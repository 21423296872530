import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import ShippingSelect from './ShippingSelect';
import AddressSelect from './AddressSelect';
import PaymentSelect from './PaymentSelect';
import CheckoutProducts from './CheckoutProducts';
import CheckoutTotal from './CheckoutTotal';
import CreditOption from './CreditOption';
import MyButton from './form/MyButton';
import ProductUpdate from './ProductUpdate';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Checkout({ navigation, route }) {

   // console.log('route: ' + JSON.stringify(route));
    const { user } = useAuth();

    let aID = route.params? route.params.addressID : 0;
    const productID = route.params? route.params.productID : null;
    const optionID = route.params? route.params.optionID : null;

   // console.log('address: ' + address);

    const [shipping, setShipping] = useState([]);
    const [shippingID, setShippingID] = useState(2);
    const [shippingFee, setShippingFee] = useState(7);

    const [address, setAddress] = useState(null);

    const [credit, setCredit] = useState(0);  // credit to use

    const [sum, setSum] = useState(0);
    const [count, setCount] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data: shipping } = await api.get('/Order/Shipping/');
        setShipping(shipping);

      //  console.log('shipping: ' + JSON.stringify(shipping));

        if (productID == null)
            fetchCartSum();
    }

    const fetchCartSum = async () => {
        const { data } = await api.get('/Cart/Sum/' + user.personID);
        setSum(data.item1);
        setCount(data.item2);
    }

    useEffect(() => {
        let t = sum + shippingFee - credit;
        setTotal(t.toFixed(2));
    }, [sum, shippingFee, credit]);

    const update = (sum, count) => {
        setSum(sum);
        setCount(count);
    }

    const goPaypal = async () => {
        navigation.navigate("PayPal2", { amount: total, credit: credit, addressID: address.aid, shippingID: shippingID });
    }

    return (
        <View style={styles.c1}>
            <View style={styles.c2}>
                <View style={{marginTop: 4}}>
                    <ShippingSelect data={shipping} onSelect={(sid, c) => { setShippingID(sid); setShippingFee(c) }} />
                </View>
                <View style={styles.row}>
                    <AddressSelect addressID={aID} navigation={navigation} onLoad={a => setAddress(a)} />
                </View>
                <View style={styles.row}>
                    {productID? <ProductUpdate productID={productID} optionID={optionID} onUpdate={(s, c) => update(s, c)} /> :
                                <CheckoutProducts />}
                </View>
                
                <CreditOption sum={sum} onChange={c => setCredit(c)} />

                <View style={styles.row}>
                    <CheckoutTotal sum={sum} shippingFee={shippingFee} credit={credit} count={count} total={total} />
                </View>
                <View style={styles.row}>
                    {address && <MyButton text="Place Order" color="black" onPress={() => goPaypal()} />}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    c1: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#EAF2F8',
    },
    c2: {
        width: '70%',
        padding: 8
    },
    row: {
        marginTop: 8
    },
    vPay: {
        height: 50,
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
    },

})