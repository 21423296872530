import React, { useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity, Text, Image, StyleSheet } from 'react-native';

import color from '../config/color';
import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function HSKLessons({ navigation, route }) {
    const { level } = route.params;
    const { user } = useAuth();

    const [data, setData] = useState([]);
    const c = color['hsk' + level];

    const domain = server["domain"];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async() => {
        const { data } = await api.get('/Lesson/' + level);
        setData(data);
    }

    const chooseLesson = (lesson) => {
        if (lesson.isFree || user && user.isMember)
            return navigation.navigate("LessonTabs", { lesson: lesson });

        if (user == undefined)
            return navigation.navigate("LoginHSK", { prevScreen: 'HSKLessons', params: { level: level }});

        if (!user.isMember)
            return navigation.navigate("PlanHSK");
    }

    return (
        <View style={styles.container}>
        <ScrollView>
            <View style={styles.list}>
                {data.map(item =>
                    <View key={item.lid}>
                        <TouchableOpacity onPress={() => chooseLesson(item)}>
                            <View style={styles.card}>
                                <Image style={styles.image} source={{uri: domain + '/images/lesson/' + item.lid + '.jpg'}} />
                                <View style={[styles.textV, item.isFree? {backgroundColor: '#7FFF00'} : {backgroundColor: c}]}>
                                    <Text style={[styles.chinese, level==2 || level==6? {color:'white'}:{color:'black'}]}> {item.chinese}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
            )}
            </View>
        </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10,
        backgroundColor: '#EBF5FB'
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 20
    },
    card: {
        width: 170,
        height: 220,
        margin: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        resizeMode: 'contain',
        width: 170,
        height: 170,
        alignSelf: 'center'
    },
    textV: {
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 10
    },
    lesson: {
        fontSize: 20,
    },
    lessonID: {
        fontSize: 30,
        marginBottom: 20
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 18,
    },
})