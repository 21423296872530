import React, { useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, Message, ErrorMessage } from './form';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import IconView from './IconView';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';

const schema = Yup.object().shape({
    email: Yup.string().required().email().label("Email")
})

export default function PassForgot() {

    const domain = server["domain"];

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendPass = async (email) => {
        setError(false);
        setSuccess(false);

        const { data } = await api.put("/User/SendPass/" + email);

        if (data < 0)
            setError(true);
        else
            setSuccess(true);
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.envelope} source={{uri: domain + '/images/common/envelope.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={styles.form}>
                    <IconView color='#febb22'>
                        <MaterialCommunityIcons name="email" size={100} color="white" />
                    </IconView>
                    <Form
                        initialValues={{ email: '' }}
                        onSubmit={({email}) => sendPass(email) }
                        validationSchema={schema}
                    >
                        <View style={{marginBottom: 10}}>
                        {error && <ErrorMessage text="Email account not exist." />}
                        {success && <Message text="We have sent a new password to your email." />}
                        </View>
                        <FormField 
                            name="email"
                            icon="email"
                            placeholder="Email"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="email-address"
                            textContentType="emailAddress"
                        />
                        <View style={styles.buttonV}>
                            <SubmitButton text="Send Password" color="#48adff" />
                        </View>
                    </Form>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    form: {
        width: '75%',
        height: 'auto',
        padding: 50,
        borderRadius: 30,
        alignItems: 'center',
        backgroundColor: '#e9f3fd',
    },
    envelope: {
        width: 392,
        height: 456,
    },
    buttonV: {
        width: '100%',
        height: 100,
    }
})