import React, { useState, useEffect } from 'react';
import { View, Pressable, ScrollView, Text, Image, StyleSheet } from 'react-native';

import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function CultureMenu({ navigation }) {

    const { user } = useAuth();

    const [data, setData] = useState([]);

    const domain = server["domain"];

    const fetchData = async () => {
        const { data } = await api.get('/Advanced/Culture/');
        setData(data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelect = async (cid, isFree) => {
        if (isFree || user && user.isMember)
            return navigation.navigate("Culture", { cid: cid });
          
        if (user == undefined)
            return navigation.navigate("Login6", { prevScreen: 'CultureMenu'});
        
        if (!user.isMember)
            return navigation.navigate("Plan6");
    }

    return (
        <View style={styles.container}>
        <ScrollView>
            <View style={styles.list}>
                {data.map(item =>
                     <Pressable onPress={() => handleSelect(item.cid, item.isFree, item.isDone)} key={item.cid}>
                        <View style={styles.card}>
                            <Image style={styles.image} source={{uri: domain + '/images/culture/' + item.cid + '.jpg'}} />
                            <View style={[styles.textV, item.isFree? {backgroundColor: '#7FFF00'} : {backgroundColor: '#D7BDE2'}]}>
                                <Text style={styles.chinese}>{ item.chinese }</Text>
                            </View>
                        </View>
                    </Pressable>
                )}
            </View>
        </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
        padding: 10
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'center',
        marginBottom: 20
    },
    card: {
        width: 170,
        height: 220,
        margin: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        resizeMode: 'contain',
        width: 170,
        height: 170,
        alignSelf: 'center'
    },
    soon: {
        resizeMode: 'contain',
        width: 100,
        height: 26,
        zIndex: 10
    },
    textV: {
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 10
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 18,
    },
})