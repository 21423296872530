import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { ErrorMessage, FormLine, SubmitButton } from './form';
import { Formik } from 'formik';

import api from '../api/client';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    chinese: Yup.string().label("词汇").min(1)
});

export default function IdiomSearch({ onSearch }) {

    const [values, setValues] = useState({chinese: ''});
    const [error, setError] = useState(false);

    const search = async (values) => {
        const { chinese } = values;
        const { data } = await api.get("/Advanced/Idiom/S/" + chinese.trim());

        if (data.length == 0) {
            setError(true);
        } else {
            onSearch(data);
            setError(false);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <Formik
                    enableReinitialize
                    initialValues={values}
                    onSubmit={(values, actions) => { search(values) }}
                    validationSchema={schema}
                >
                    {({ handleChange, values }) => (
                <>
                    <FormLine 
                        name="chinese"
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.chinese}
                        onChangeText={handleChange('chinese')}
                    />
                    <View style={{marginLeft: 5}}>
                        <SubmitButton text="Search" color="dodgerblue" width={80} />
                    </View>
                    </>
                    )}
                </Formik>
            </View>
            {error && <ErrorMessage text="Not Found" />}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
    },
    form: {
        flexDirection: 'row',
        marginTop: 5
    },
})