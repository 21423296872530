import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';

import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function SayingMenu({ navigation }) {

    const { user } = useAuth();
    const [data, setData] = useState([]);

    const domain = server["domain"];

    const fetchData = async () => {
        const { data } = await api.get('/Advanced/Saying/');
        setData(data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelect = async (cid) => {
        return navigation.navigate("Saying", { cid: cid });
    }

    // const handleSelect = async (cid, isFree) => {
    //     if (isFree || user && user.isMember)
    //         return navigation.navigate("Saying", { cid: cid });
          
    //     if (user == undefined)
    //         return navigation.navigate("Login6", { prevScreen: 'SayingMenu'});
        
    //     if (!user.isMember)
    //         return navigation.navigate("Plan6");
    // }

    return (
        <View style={styles.container}>
        <ScrollView>
            <View style={styles.list}>
                {data.map(item =>
                    <View key={item.cid}>
                        <TouchableOpacity onPress={() => handleSelect(item.cid, item.isFree)}>
                            <View style={styles.card}>
                                <Image style={styles.image} source={{uri: domain + '/images/saying/' + item.cid + '.jpg'}} />
                                <View style={[styles.textV, item.isFree? {backgroundColor: '#7FFF00'} : {backgroundColor: 'orange'}]}>
                                    <Text style={styles.chinese}>{ item.chinese }</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
            )}
            </View>
        </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10,
        backgroundColor: '#EBF5FB'
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 20
    },
    card: {
        width: 170,
        height: 220,
        margin: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        resizeMode: 'contain',
        width: 170,
        height: 170,
        alignSelf: 'center'
    },
    textV: {
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 10
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 18,
    },
})