import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import VocabManager from '../components/VocabManager';
import VocabularyMenu from '../components/VocabularyMenu';
import VocabularyTable from '../components/VocabularyTable';
import VocabularyCat from '../components/VocabularyCat';
import PlanNavigator from './PlanNavigator';

const Stack = createNativeStackNavigator();

const VocabularyNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>
            <Stack.Screen name="VocabularyMenu" component={ VocabularyMenu } options={{ headerShown: false }} />
            {!user && <Stack.Screen name="Login5" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            <Stack.Screen name="Plan1" component={ PlanNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="VocabularyCat" component={ VocabularyCat } options={{ title: '按拼音分类' }} />
            <Stack.Screen name="VocabManager" component={ VocabManager } options={{ headerShown: false }} />
            <Stack.Screen name="VocabularyTable" component={ VocabularyTable } options={{ title: '返回' }} />
        </Stack.Navigator>
    );
}

export default VocabularyNavigator;