import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function Countdown({ deadline }) {
    const end = Date.parse(deadline);

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        setInterval(() => {
            update();
        }, 1000);
    }, []);

    const update = () => {
        let now = new Date();
       // console.log('now: ', now);

        let diffMs = (end - now); // milliseconds
        let diffDays = Math.floor(diffMs / 86400000); // days
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000);  //minutes
        let diffSecs = Math.round(((diffMs % 86400000) % 3600000) / 3600000);  //seconds

        setDays(diffDays);
        setHours(diffHrs);
        setMinutes(diffMins);
        setSeconds(diffSecs);
    }

    const update2 = () => {
        let now = new Date();
     //   console.log('now: ', now);

        let diffMs = (end - now); // milliseconds
        let diffDays = Math.floor(diffMs / 86400000); // days
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000);  //minutes

        setDays(diffDays);
        setHours(diffHrs);
        setMinutes(diffMins);

    }

    if (minutes == 0)
        return null;

    return (
        <View style={styles.container}>
            {days > 1? <Text style={styles.text}>{days} days </Text>:<Text style={styles.text}>{days} day </Text>}
            <Text style={styles.text}>{hours}h </Text>
            <Text style={styles.text}>{minutes}m </Text>
            <Text style={styles.text}>{seconds}s</Text>
            <Text style={styles.text}> left</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'black',
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 15,
        marginVertical: 15
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 24,
        color: '#FF004C',
    },
})