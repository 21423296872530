import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { LessonContext } from './Context';

import { Audio } from 'expo-av';
import server from '../config/server';

export default function Sentence({ data, newWords, onFinish }) {
    const { sid, textID, pinyin, chinese, english, onLeft, duration, url } = data;

    const domain = server['domain'];
    const lesson = React.useContext(LessonContext);

    let hsk = 2;

    if (lesson != undefined)
        hsk = lesson.hsk;

    const [words, setWords] = useState([]);
    const [opacity, setOpacity] = useState(0);

    const marks = [',','.','?','!',':',';'];

    useEffect(() => {
        splitWords();
        setTimeout(() => { 
            setOpacity(1);
            playAudio();
        }, 1000);
    }, []);

    const playAudio = async () => {
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/text/HSK' + hsk + '/' + textID + '/' + url + '.mp3'});
        await sound.playAsync();

        setTimeout(() => {
            onFinish();
        }, duration * 1000 + 2000);
    }

    const splitWords = () => {
        let pins = pinyin.trim().split(/\s+/);
        let words = chinese.trim().split(/\s+/);

        for (let i = 0; i < pins.length; i++) {
            if (marks.indexOf(pins[i]) >= 0)
                pins[i] = ' ';
        }

        let combine = words.map((e, i) => [e, pins[i]]);
        setWords(combine);
    }

    const renderItem = ({item}) => {
        const color = newWords.indexOf(item[0]) >= 0? '#FF004C' : 'black';
        return (
            <View style={styles.word}>
                <Text style={styles.pinyin}>{item[1]}</Text>
                <Text style={[styles.chinese, {color: color}]}>{item[0]}</Text>
            </View>
        );
    }

    return (
        <Pressable onPress={() => playAudio()}>
            <View style={[styles.p, {'opacity': opacity}, onLeft? { alignSelf: 'flex-start' } : { alignSelf: 'flex-end' }]}>
                <View style={{flex: 1, flexDirection: 'column'}}>
                    <FlatList
                        data={words}
                        keyExtractor={(item, index) => index}
                        renderItem={renderItem}
                        horizontal={true}
                    ></FlatList>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontSize: 5}}>{sid}</Text>
                        <Text style={styles.english}>{english}</Text>
                    </View>
                </View>
                {onLeft? <Ionicons name='caret-back-outline' size={50} color='black' style={styles.left} />:
                         <Ionicons name='caret-forward-outline' size={50} color='black' style={styles.right} />}
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    p: {
        zIndex: 100,
        transitionProperty: 'opacity',

        opacity: 0,
        height: 150,
        backgroundColor: 'white',

        borderColor: 'black',
        borderWidth: 4,
        borderRadius: 20,

        marginLeft: 50,
        marginRight: 50,
        marginBottom: 30,

        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,

        position: 'relative',
    },
    left: {
        position: 'absolute',
        left: -36,
        top: 40,
        zIndex: 2,
    },
    right: {
        position: 'absolute',
        right: -36,
        top: 40,
        zIndex: 2
    },
    word: {
        flex: 1,
        flexDirection: 'column',
        paddingRight: 10,
    },
    pinyin: {
        alignSelf: 'center',
        fontSize: 20
    },
    chinese: {
        alignSelf: 'center',
        fontFamily: 'MicrosoftBlack',
        //fontFamily: 'STXinwei',
        fontSize: 35,
        marginTop: 10
    },
    english: {
        fontFamily: 'Candara',
        marginTop: '8px',
        fontSize: 20
    }
})

//中文字体
//https://xuui.net/ui-design/english-name-of-chinese-characters-table.html