import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, Image, FlatList, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';
import color from '../config/color';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

const hsks = [1,2,3,4,5,6];
const { hsk1, hsk2, hsk3, hsk4, hsk5, hsk6 } = color;
const colors = [hsk1, hsk2, hsk3, hsk4, hsk5, hsk6];

export default function VocabularyMenu({ navigation }) {
    const { user } = useAuth();
    const [cats, setCats] = useState([]);

    const domain = server["domain"];

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/');
        setCats(data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelect = async (catID, isFree) => {
        if (isFree || user && user.isMember)
            return navigation.navigate("VocabManager", { catID: catID });
          
        if (user == undefined)
            return navigation.navigate("Login5", { prevScreen: 'VocabularyMenu'});
        
        if (!user.isMember)
            return navigation.navigate("Plan1");
    }

    const handleCat = (HSK) => {
        if (user == undefined)
            return navigation.navigate('Login5', { prevScreen: 'VocabularyMenu'});

        if (user.isMember)
            return navigation.navigate("VocabularyCat", { HSK: HSK });

        if (!user.isMember)
            navigation.navigate("Plan1");
    }

    const renderItem = ({item}) => {
        let bgColor = item.isFree? {backgroundColor: '#7fff00'} : {backgroundColor: '#00BFFF'};
        return (
            <Pressable onPress={() => handleSelect(item.cid, item.isFree)}>
                <View style={styles.card}>
                    <View style={styles.imageContainer}>
                        <Image style={styles.image} source={{uri: domain + '/images/vocab/' + item.cid + '/' + item.imageName + '.jpg'}} />
                    </View>
                    <View style={[styles.textV, bgColor]}>
                        <Text style={styles.chinese}>{ item.chinese }</Text>
                    </View>
                </View>
            </Pressable>
        );
    };

    const renderHSK = ({ item, index }) => (
        <Pressable onPress={() => handleCat(index + 1)}>
            <View style={[styles.card, {backgroundColor: colors[index]}]}>
                <Text style={styles.hsk}>HSK</Text>
                <Text style={styles.level}>{index + 1}</Text>
            </View>
        </Pressable>
    );

    return (
        <View style={styles.container}>
            <ScrollView style={{width: '95%'}}>
            <View style={styles.v2}>
                <FlatList
                    data={cats}
                    numColumns={6}
                    keyExtractor={item => item.cid}
                    renderItem={renderItem}
                ></FlatList>
                <FlatList
                    data={hsks}
                    keyExtractor={item => item.cid}
                    renderItem={renderHSK}
                    numColumns={6}
                ></FlatList>
            </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    v2: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        width: 150,
        height: 180,
        margin: 20,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    imageContainer: {
        width: 150,
        height: 150,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
    },
    image: {
        resizeMode: 'contain',
        width: 140,
        height: 140
    },
    textV: {
        width: '100%',
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#58D68D'
    },
    chinese: {
        color: 'black',
        fontFamily: 'MicrosoftBlack',
        fontSize: 18,
    },
    hsk: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 30,
    },
    level: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 40,
    },
})