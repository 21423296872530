import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import Pagination from './Pagination';
import IdiomList from './IdiomList';
import Idiom from './Idiom';
import IdiomSearch from './IdiomSearch';

import api from '../api/client';

export default function IdiomManager() {

    const [total, setTotal] = useState(0);
    const [pageID, setPageID] = useState(1);
    const [cID, setCID] = useState(1);
    const [data, setData] = useState([]);

    const pageSize = 10;

    useEffect(() => {
        fetchTotal();
        updateData(pageID);
    }, []);

    const fetchTotal = async () => {
        const { data: total } = await api.get('/Advanced/Idiom/Total');
        setTotal(total);
    }

    const updateData = async (pageID) => {
        setPageID(pageID);
        const { data } = await api.get('/Advanced/Idiom/' + pageID + '/' + pageSize);
        setData(data);
        setCID(data[0].cid);
    }

    const handleSearch = (list) => {
        if (list.length > 0) {
            setData(list);
            setCID(list[0].cid);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                {total > 0 && <Pagination total={total} pageSize={pageSize} pageID={pageID} onChange={pageID => updateData(pageID)} />}
                <Idiom cID={cID} />
            </View>
            <View style={styles.right}>
                <View style={{position: 'relative', left: -100}}>
                    <IdiomSearch onSearch={list => handleSearch(list) } />
                </View>
                {data.length > 0 && <IdiomList cID={cID} data={data} onSelect={cid => setCID(cid)} />}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '68vw'
    },
    right: {
        width: '18vw',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
    },
})