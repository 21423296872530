import React from "react";
import { View, Text, StyleSheet } from 'react-native';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ButtonWrapper } from "./ButtonWrapper";

export default function PayPal({ navigation, route }) {
    const { id, amount, type, coupon, credit } = route.params;

    const handlePaid = (date) => {
        navigation.navigate("Receipt", { date: date });
    }

	return (
		<View style={styles.container}>
            <View style={styles.r1}>
                <Text style={styles.text}>Please patiently wait for the PayPal buttons to show up.</Text>
                <Text style={styles.text}>This may take a few seconds.</Text>
            </View>
            <View style={styles.r2}>
            <PayPalScriptProvider
                options={{
                    "client-id": "AVQNz4WzWKbNiADHQTQhkGnXEgajRLKAOZifTSsoFYBuR6m8TAC0Yc0v-xJTj0jFx2Hb_wfNGM6suj2Y",
                    components: "buttons",
                    currency: "USD",
                    "disable-funding": ['venmo','paylater']
                }}
            >
				<ButtonWrapper id={id} amount={amount} type={type} coupon={coupon} credit={credit} showSpinner={false}
                                onPaid={date => handlePaid(date)} />
			</PayPalScriptProvider>
            </View>
		</View>
	);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
    },
    r1: {
        height: '20%'
    },
    r2: {
        width: 600,
        height: 300,
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 20
    }
})