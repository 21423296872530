import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';
import color from '../config/color';

const { hsk1, hsk2, hsk3, hsk4, hsk5, hsk6 } = color;
const colors = [hsk1, hsk2, hsk3, hsk4, hsk5, hsk6];

export default function VocabularyTable({ route }) {
    const { HSK, Cat } = route.params;
    const domain = server["domain"];
    
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/HSK/' + HSK + '/' + Cat);
        setData(data);
    } 

    useEffect(() => {
        fetchData();
    }, []);

    const playAudio = async (item) => {
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/text/HSK'+ HSK + '/' + item.textID + '/v/' + item.audioID + '.mp3'});
        await sound.playAsync();
    }

    const renderHeader = () => (
        <View style={[styles.header, {backgroundColor: colors[HSK-1]}]}>
            <View style={styles.cn}>
                <Text style={styles.hText}>词语</Text>
            </View>
            <View style={styles.lb}>
            </View> 
            <View style={styles.pn}>
                <Text style={styles.hText}>拼音</Text>
            </View>
            <View style={styles.cx}>
                <Text style={styles.hText}>词性</Text>
            </View>
            <View style={styles.en}>
                <Text style={styles.hText}>英文释义</Text>
            </View>
            <View style={styles.le}>
                <Text style={styles.hText}>课号</Text>
            </View>
        </View>
    );

    const renderItem = ({item}) => {
        const {pinyin, cixing, chinese, english, lessonID} = item;
        return (
            <Pressable onPress={() => playAudio(item)}>
                <View style={[styles.card, {backgroundColor: color['' + cixing]}]}>
                    <View style={styles.cn}>
                        <Text style={styles.chinese}>{chinese}</Text>
                    </View>
                    <View style={styles.lb}>
                        <MaterialCommunityIcons name="trumpet" size={25} color="black" style={styles.icon} />
                    </View>  
                    <View style={styles.pn}>
                        <Text style={styles.pinyin}>{pinyin}</Text>
                    </View>
                    <View style={styles.cx}>
                        <Text style={styles.cixing}>{cixing}</Text>
                    </View>
                    <View style={styles.en}>
                        <Text style={styles.english}>{english}</Text>
                    </View>
                    <View style={styles.le}>
                        <Text style={{fontSize: 15}}>{lessonID}</Text>
                    </View>
                </View>
            </Pressable>
            )
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                {renderHeader()}
            </View>
            <View style={styles.row3}>
                <FlatList
                    data={data}
                    numColumns={1}
                    keyExtractor={item => item.vid}
                    renderItem={renderItem}
                    style={styles.list}
                ></FlatList>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        justifyContent: 'center',
        paddingHorizontal: 100,
    },
    title: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 25,
        alignSelf: 'center',
        marginVertical: 15
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        width: '95%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    hText: {
        fontSize: 16
    },
    card: {
        flex: 1,
        flexDirection: 'row',
        width: '95%',
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        marginBottom: 5
    },
    cn: {
        width:'20%',
        padding: 10,
        paddingLeft: 20
    },
    chinese: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 30,
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 20
    },    
    pn: {
        width:'20%',
        fontFamily: 'MicrosoftBlack',
        fontSize: 15,
    },
    lb: {
        width:'5%',
        fontFamily: 'MicrosoftBlack',
        fontSize: 15,
    },
    cx: {
        width:'10%',
        fontFamily: 'MicrosoftBlack',
        fontSize: 15,
    },
    en: {
        width:'40%',
        fontFamily: 'MicrosoftBlack',
        fontSize: 15,
    },
    le: {
        width:'5%',
        fontFamily: 'MicrosoftBlack',
        fontSize: 15,
    },
    empty: {
        height: 100
    },
    pinyin: {
        fontSize: 20
    },
    cixing: {
        fontFamily: 'Comic Sans MS',
        fontSize: 18
    },
    row: {
        marginVertical: 10
    },
    row3: {
        flex: 1,
        marginBottom: 50
    }
})