import React, { useState }  from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Photo from './Photo';
import PayPalUpdate from './PayPalUpdate';
import GenderSwitch from './GenderSwitch';
import MemberDate from './MemberDate';

import useAuth from '../hooks/useAuth';

export default function UserInfo() {

    const { user } = useAuth();

    console.log('user', JSON.stringify(user));

    const [male, setMale] = useState(user.gender);

    return (
        <View style={styles.c1}>
            <View style={styles.c2}>
                <View style={{ marginRight: 20 }}>
                    <Photo personID={user.personID} male={male} />
                </View>
                <View style={styles.info}>
                    <View style={styles.row}>
                        <View style={styles.left}>
                            <Text style={styles.text}>Name:</Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.text}>{ user.firstName + ' ' + user.lastName }</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.left}>
                            <Text style={styles.text}>Email:</Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.text}>{ user.email }</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.left}>
                            <Text style={styles.text}>Gender:</Text>
                        </View>
                        <View style={styles.right}>
                            <GenderSwitch personID={user.personID} gender={user.gender} onUpdate={g => setMale(g)} />
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.left}>
                            <Text style={styles.text}>PayPal: </Text>
                        </View>
                        <View style={styles.right}>
                            <PayPalUpdate />
                        </View>
                    </View>
                    {user.isMember && <View style={styles.row}>
                        <Text style={styles.text}>Membership expires on:  </Text>
                        <MemberDate date={user.memberToDate} />
                    </View>}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    c1: {
        flex: 1,
        backgroundColor: 'white',
        backgroundColor: '#D1F2EB',
        borderRadius: 10,
        padding: 8,
    },
    c2: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 40,
    },
    info: {
        justifyContent:'flex-start',
        alignItems: 'flex-start',
        marginLeft: 50
    },
    row: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    left: {
        width: '8vw',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    right: {
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    renew: {
        width: 40, 
        height: 30, 
        backgroundColor: 'limegreen',
        alignItems: 'center',
        justifyContent: 'center',
    },  
    text: {
        fontFamily: 'Comic Sans MS',
        fontSize: 18,
    },
})