import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import SayingMenu from '../components/SayingMenu';
import Saying from '../components/Saying';
import SayingText from '../components/SayingText';

const Stack = createNativeStackNavigator();

const SayingNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="SayingMenu" component={ SayingMenu } options={{ title: '返回' }} />
            <Stack.Screen name="Saying" component={ Saying } options={{ headerShown: false }} />
            <Stack.Screen name="SayingText" component={ SayingText } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default SayingNavigator;