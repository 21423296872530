import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';

import color from '../config/color';
import api from '../api/client';

//const hsks = [1,2,3,4,5,6];
const { hsk1, hsk2, hsk3, hsk4, hsk5, hsk6 } = color;
const colors = [hsk1, hsk2, hsk3, hsk4, hsk5, hsk6];

const cat = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

export default function VocabularyCat({ navigation, route }) {
    const { HSK } = route.params;
    const [count, setCount] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/HSK/' + HSK);
        setCount(data);
    }

    const handleChoose = (item) => {
        navigation.navigate("VocabularyTable", { HSK: HSK, Cat: item });
    }

    const renderItem = ({item, index}) => (
        count[index] > 0?
        <Pressable onPress={() => handleChoose(item)}>
            <View style={[styles.card, {backgroundColor: colors[HSK-1]}]}>
                <Text style={styles.cat}>{item}</Text>
                <Text style={styles.number}>{count[index]}</Text>
            </View>
        </Pressable>
        :
        <View style={[styles.card, {backgroundColor: '#EAEDED'}]}>
            <Text style={styles.cat}>{item}</Text>
            <Text style={styles.number}>{count[index]}</Text>
        </View>
    );

    return (
        <View style={styles.container}>  
            <View>
                <FlatList
                    data={cat}
                    numColumns={7}
                    keyExtractor={(item, index) => index}
                    renderItem={renderItem}
                ></FlatList>
            </View>  
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    card: {
        width: 100,
        height: 100,
        margin: 20,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        overflow: 'hidden'
    },
    cat: {
        fontFamily: 'Candara',
        fontSize: 50,
    },
    number: {
        fontSize: 15
    }
})