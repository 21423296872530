import React, { useState }  from 'react';
import { Switch } from 'react-native';
import api from '../api/client';

export default function GenderSwitch({ personID, gender, onUpdate }) {

    const [male, setMale] = useState(gender);

    const toggleGender = async () => {
        await api.put('/User/Gender/' + personID + '/' + !male);
        onUpdate(!male);
        setMale(!male);
    }

    return (
        <Switch
            style={{ transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }], marginLeft: 20 }}
            trackColor={{false: 'black', true: 'black'}}
            thumbColor={male? '#60a6e1' : '#ff99ae'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleGender}
            value={male}
        />
    );
}