import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import Pagination from './Pagination';
import PauseButton from './PauseButton';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';

export default function BookText({ navigation, route }) {
    const { BID } = route.params;

    const domain = server['domain'];

    const [pages, setPages] = useState([]);
    const [index, setIndex] = useState(0);

    const [pID, setPID] = useState(0);
    const [chinese, setChinese] = useState('');
    const [english, setEnglish] = useState('');

    const [paused, setPaused] = useState(false);
    const [finished, setFinished] = useState(false);

    const audio = React.useRef(new Audio.Sound());

    useEffect(() => {
        fetchPages();

        setInterval(() => {
            checkStatus();
        }, 5000);

        return () => {
            audio.current.unloadAsync();
        }
    }, []);

    useEffect(() => {
        if (pages.length < 1)
            return;
        
        if (index >= pages.length)
            return navigation.navigate("BookMenu");

        updateData();
    }, [index, pages]);

    useEffect(() => {
        if (finished && !paused) {
            setIndex(index + 1);
            return;
         }   
         if (!finished && paused) {
             pauseAudio();
             return;
         }
         if (!finished && !paused) {
             playAudio();
             return;
         }
    }, [paused, finished]);

    const fetchPages = async() => {
        const { data } = await api.get('/Book/Pages/' + BID);
        setPages(data);
    }

    const updateData = async() => {
        const { data } = await api.get('/Book/' + BID + '/' + pages[index]);
        setChinese(data.chinese);
        setEnglish(data.english);
        setPID(data.pid);
        loadAudio();
    }

    const loadAudio = async () => {
        audio.current.unloadAsync();
     
        const uri = domain + '/sound/book/' + BID + '/' + (index + 1) + '.mp3';

        const { sound } = await Audio.Sound.createAsync({uri: uri});
        audio.current = sound;

        setFinished(false);
        playAudio();
    }

    const playAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && !status.isPlaying)
                audio.current.playAsync();
        } catch (error) {}
    }
    
    const pauseAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && status.isPlaying)
                audio.current.pauseAsync();
        } catch (error) {}
    }

    const checkStatus = async () => {
        const status = await audio.current.getStatusAsync();
        if (status.didJustFinish)
            setFinished(true);
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                {pages.length > 0 && <Pagination total={pages.length} pageSize={1} pageID={index+1} onChange={item => setIndex(item-1)} />}
                <Text style={styles.chinese}>{chinese}</Text>
                <Text style={{fontSize: 16}}>[{pID}]</Text>
                <PauseButton paused={paused} onPause={() => setPaused(true)} onPlay={() => setPaused(false)} />
            </View>
            <View style={styles.right}>
                <ScrollView style={{padding: 20}}>
                    <Text style={styles.english}>{english}</Text>
                </ScrollView>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '70%',
        flexDirection: 'column',
        padding: 40
    },
    right: {
        width: '30%',
    },
    card: {
        backgroundColor: 'white',
        padding: 10,
        borderWidth: 1,
        borderColor: 'lightgray',
        marginLeft: -20,
        marginRight: 30
    },
    image: {
        resizeMode: 'contain',
        marginRight: 50
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 40,
        overflow: 'hidden',
        lineHeight: 80,
        letterSpacing: 3
    },
    english: {
        fontFamily: 'STZhongsong',
        fontSize: 22,
        lineHeight: 40
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    boxStyle: {
        padding: 10,
        borderWidth: 2,
        fontFamily: 'STZhongsong',
        fontSize: 30,
        width: '90%',
        borderRadius: 4,
        borderColor: 'dodgerblue'
    },
    contentV: {
        minHeight: '80vh',
        padding: 20,
    },
    pauseV: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 30
    }
})