import React from 'react';
import { View, FlatList, Text, Pressable, StyleSheet } from 'react-native';

export default function IdiomList({ cID, data, onSelect }) {

    const renderItem = ({item}) => {
        const { cid, chinese } = item;
        const bgColor = item.cid == cID? {backgroundColor: 'pink'} : {backgroundColor: 'white'}
        return (
            <Pressable onPress={() => onSelect(cid) }>
                <View style={[styles.card, bgColor]}>
                    <Text style={styles.chinese}>{ chinese }</Text>
                </View>
            </Pressable>
        );
    };

    return (
        <FlatList
            data={data}
            numColumns={1}
            keyExtractor={item => item.cid}
            renderItem={renderItem}
        ></FlatList>
    );
}

const styles = StyleSheet.create({
    card: {
        width: '15vw',
        padding: 10,
        margin: 3,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        boxShadow: '0 3px 5px rgb(0 0 0 / 0.2)',
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 20,
    },
})