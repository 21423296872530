import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, ErrorMessage } from './form';
import IconView from './IconView';
import LinkButton from './LinkButton';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

const schema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(6).max(20).label("Password")
});

export default function Login({ navigation, route }) {

    const domain = server["domain"];

    const prevScreen = route.params? route.params.prevScreen : null;   //previous screen
    const params = route.params? route.params.params : null;

    const { user, logIn } = useAuth();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [ip, setIP] = useState(null);

    useEffect(() => {
        getIP();
        if (user)
            log();
    }, [user]);

    const getIP = async () => {
        const res = await api.get("https://api.ipify.org/?format=json");
        const ip = res.data.ip;
        setIP(ip);
        console.log('ip: ', ip);
    }

    const log = async () => {
        await api.put("/User/Log/" + user.personID + "/" + ip);
    }

    const login = async (email, password) => {
        const { data } = await api.get("/User/Auth/" + email + "/" + password);

        if (data == null) {
            setError(true);
            setMessage('Invalid email or password.');
            return; 
        }

     //   console.log('token: ', JSON.stringify(data));

        logIn(data);

        if (prevScreen) {
            if (params != null)
                return navigation.navigate(prevScreen, params);
            
            return navigation.navigate(prevScreen);
        }  
        
        return navigation.navigate('VocabularyNavigator');
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.image} source={{uri: domain + '/images/common/login.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={styles.form}>
                    <IconView>
                        <Image style={styles.userIcon} source={{uri: domain + '/images/common/verified-user.png'}} />
                    </IconView>
                    <Form
                        initialValues={{email: '', password: ''}}
                        onSubmit={({email, password}) => login(email, password)}
                        validationSchema={schema}
                    >
                        {error && <View style={{marginBottom: 10}}>
                            <ErrorMessage text={message} />
                        </View>}
                        <FormField
                            name="email"
                            icon="email"
                            placeholder="Email"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="email-address"
                            textContentType="emailAddress"
                        />
                        <FormField 
                            name="password"
                            icon="lock"
                            placeholder="Password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            textContentType="password"
                            secureTextEntry={true}
                        />
                        <SubmitButton text="Login" />
                    </Form>
                    <View style={styles.links}>
                        <LinkButton text='Forgot password?' screen='PassForgot' />
                        <LinkButton text='Have no account yet?' screen='Register' />
                    </View>
                </View>          
            </View> 
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    image: {
        resizeMode: 'contain',
        width: '70vh',
        height: '60vh',
        marginTop: -100,
        zIndex: -10
    },
    form: {
        width: '75%',
        height: 'auto',
        padding: 50,
        borderRadius: 30,
        alignItems: 'center',
        backgroundColor: '#D1F2EB',
    },
    userIcon: {
        width: 146,
        height: 146,  
    },
    links: {
        marginTop: 20,
    }
})