import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import PauseButton from './PauseButton';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';

export default function CultureText({ navigation, route }) {
    const { textID } = route.params;
    const domain = server['domain'];

    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [paragraph, setParagraph] = useState(null);

    const [paused, setPaused] = useState(false);
    const [finished, setFinished] = useState(false);

    const audio = React.useRef(new Audio.Sound());

    const fetchData = async() => {
        const { data } = await api.get('/Advanced/Culture/Text/' + textID);
        setData(data);
    }

    const updateData = () => {
        setFinished(false);

        let p = data[index];

        setParagraph(p);
        loadAudio();
    }

    const loadAudio = async () => {
        audio.current.unloadAsync();

        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/culture/' + textID + '/' + (index+1) + '.m4a'});
        audio.current = sound;

        playAudio();
    }

    const playAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && !status.isPlaying)
                audio.current.playAsync();
        } catch (error) {}

        setInterval(() => {
            checkStatus();
        }, 3000);
    }

    const replayAudio = () => {
        audio.current.replayAsync();
        setPaused(false);
        setEng(false);
    }
    
    const pauseAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && status.isPlaying)
                audio.current.pauseAsync();
        } catch (error) {}
    }

    const handlePrevious = () => {
        if (index > 0)
            setIndex(index - 1);
        setEng(false);
    }

    const handleNext = () => {
        if (index < data.length - 1)
            setIndex(index + 1);
        setEng(false);
    }

    useEffect(() => {
        fetchData();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);
    
    useEffect(() => {
        if (data.length < 1) return;

        if (index < data.length)
            updateData();
        else
            navigation.navigate("CultureMenu");
    }, [index, data]);

    useEffect(() => {
        if (finished && !paused) {
            setIndex(index + 1);
            return;
        }   
        if (!finished && paused) {
            pauseAudio();
            return;
        }
        if (!finished && !paused) 
        {
            playAudio();
            return;
        }
    }, [paused, finished]);

    const checkStatus = async () => {
        const status = await audio.current.getStatusAsync();
        if (status.didJustFinish)
            setFinished(true);
    }

    const handleEng = () => {
        setPaused(true);
        setEng(true);
    }

    return (
        paragraph &&
        <View style={styles.container}>
            <View style={styles.left}>
                <Text style={styles.text}>{paragraph.chinese}</Text>
                <Text> [{paragraph.pid}]</Text>
                <PauseButton paused={paused} onPause={() => setPaused(true)} onPlay={() => setPaused(false)} />
            </View>
            <View style={styles.right}>
                <ScrollView style={{padding: 20}}>
                    <Text style={styles.english}>{paragraph.english}</Text>
                </ScrollView>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '70%',
        flexDirection: 'column',
        padding: 40
    },
    right: {
        width: '30%',
    },
    image: {
        resizeMode: 'contain',
        marginRight: 50
    },
    text: {
        fontFamily: 'STZhongsong',
        fontSize: 45,
        overflow: 'hidden',
        lineHeight: 80,
        letterSpacing: 3
    },
    english: {
        fontFamily: 'STZhongsong',
        fontSize: 24,
        lineHeight: 80,
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
})