import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import ShoppingCart from '../components/ShoppingCart';
import Checkout from '../components/Checkout';
import AddressSelect from '../components/AddressSelect';
import AddressBook from '../components/AddressBook';
import PayPal2 from '../components/PayPal2';
import Receipt from '../components/Receipt';

const Stack = createNativeStackNavigator();

const CartNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="ShoppingCart" component={ ShoppingCart } options={{ headerShown: false }} />
            <Stack.Screen name="Checkout" component={ Checkout } options={{ headerShown: false }} /> 
            <Stack.Screen name="AddressSelect" component={ AddressSelect } options={{ headerShown: false }} />
            <Stack.Screen name="AddressBook" component={ AddressBook } options={{ headerShown: false }} />
            <Stack.Screen name="PayPal2" component={PayPal2} options={{ headerShown: false }} />
            <Stack.Screen name="Receipt" component={Receipt} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default CartNavigator;