import React from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import color from '../config/color';

export default function HSKMenu({ navigation }) {
    const { hsk1, hsk2, hsk3, hsk4, hsk5, hsk6 } = color;

    const data = [1,2,3,4,5,6];
    const colors = [hsk1, hsk2, hsk3, hsk4, hsk5, hsk6];
    const lessons = [15,15,20,20,36,40];

    const renderItem = ({ item, index }) => (
        <Pressable onPress={() => navigation.navigate("HSKLessons", {level: index+1})}>
            <View style={[styles.card, {backgroundColor: colors[index]}]}>
                <Text style={styles.hsk}>HSK</Text>
                <Text style={styles.level}>{index + 1}</Text>
                <Text style={styles.lesson}>{lessons[index]} lessons</Text>
            </View>
        </Pressable>
    );

    return (
        <View style={styles.container}>
            <View>
                <FlatList
                    data={data}
                    keyExtractor={item => item}
                    renderItem={renderItem}
                    numColumns={3}
                ></FlatList>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    title: {
        fontSize: 20,
    },
    card: {
        width: 180,
        height: 250,
        padding: 30,
        margin: 30,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 5px 15px rgb(0 0 0 / 0.5)',
    },
    hsk: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 30,
        marginBottom: 20
    },
    level: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 40,
        marginBottom: 20
    },
    lesson: {
        fontFamily: 'Candara',
        fontSize: 20,
    },
})