import React, { useState, useEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';

import Cixing from './Cixing';
import VocabularyCard from './VocabularyCard';

import api from '../api/client';
import { LessonContext } from './Context';

export default function HSKVocabulary() {

    const lesson = React.useContext(LessonContext);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const filter = (cid) => {
        if (cid > 0) {
            const se = data.filter(d => d.cid == cid);
            setSelected(se);
        } else {
            setSelected(data);
        }
    }

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/Lesson/' + lesson.lid);
        setData(data);
        setSelected(data);
    }

    return (
        <View style={styles.container}>
            <Cixing onSelect={cid => filter(cid)} />
            <ScrollView>
                <View style={styles.list}>
                    {selected.map(item =>
                        <View key={item.vid}>
                            <VocabularyCard data={item} />
                        </View>
                )}
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
})