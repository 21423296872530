import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function AddressCard({ address }) {

    let { firstName, lastName, address1, address2, city, state, country, countryCode, zipCode, phone} = address;
    state = state == "No"? "" : state + ',';

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.name}>{firstName} {lastName}</Text>
                <Text style={styles.address}>+{countryCode} {phone}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.address}>{address1}  </Text>
                <Text style={styles.address}>{address2}</Text>
            </View>
            <Text style={styles.address}>{city}, {state} {country}, {zipCode}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '50vw',
        height: 'auto',
        overflow: 'hidden',
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingHorizontal: 30
    },
    row: {
        flexDirection: 'row',
        marginBottom: 5
    },
    name: {
        fontSize: 20,
        marginRight: 20,
        fontWeight: 'bold'
    },
    address: {
        fontSize: 20
    }
})