import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Account from '../components/Account';
import PayPal from '../components/PayPal';
import PayPal2 from '../components/PayPal2';

const Stack = createNativeStackNavigator();

const AccountNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Account" component={ Account } options={{ headerShown: false }} />
            <Stack.Screen name="PayPal" component={PayPal} options={{ headerShown: false }} />
            <Stack.Screen name="PayPal2" component={PayPal2} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default AccountNavigator;