import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import AddressCard from './AddressCard';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function AddressSelect({ addressID, navigation, onLoad }) {

    const { user } = useAuth();
    const [address, setAddress] = useState(null);

    useEffect(() => {
        fetchData();
    }, [addressID]);

    const fetchData = async () => {
        if (addressID > 0) {
            const { data } = await api.get('/Address/' + addressID);
            setAddress(data);
            onLoad(data);
        }  
        else {
            const { data } = await api.get('/Address/Default/' + user.personID);
            setAddress(data);
            onLoad(data);
        }
    }

    return (
        <View style={styles.container}>
            <Pressable onPress={() => navigation.navigate("AddressBook")} style={styles.row}>
            {address? 
            <AddressCard address={address} /> : <Text style={styles.text}>Add New Address</Text>}
                <View style={{flex: 1, alignItems: 'flex-end', marginRight: 10}}>
                    <FontAwesome5 name='chevron-right' color='black' size={30} />
                </View>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        padding: 20
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: '#FF004C'
    }
})