import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';

export default function Pagination({ total, pageSize, pageID, onChange }) {

    const [pages, setPages] = useState([]);
    const [startID, setStartID] = useState(1);

    const count = total % pageSize === 0? Math.floor(total / pageSize) : Math.floor(total / pageSize) + 1;
    const len = count > 11? 11 : count;

    useEffect(() => {
        if (pageID > 5 && pageID <= count - 5)
            setStartID(pageID - 5);
    }, [pageID]);

    useEffect(() => {
        updatePages();
    }, [startID]);

    const handlePress = (item) => {
        onChange(item);
    }

    const updatePages = () => {           
        const pages = Array.from({length: len}, (_, i) => i + startID)
        setPages(pages);
    }

    const renderItem = ({item}) => {
        const bgColor = item == pageID? {backgroundColor:'pink'}:{backgroundColor: '#dcf3ff'};
        return (
            <Pressable onPress={() => handlePress(item)}>
                <View style={[styles.card, bgColor]}>
                    <Text style={styles.number}>{item}</Text>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.list}>
            <FlatList
                data={pages}
                horizontal={true}
                keyExtractor={item => item}
                renderItem={renderItem}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
    },
    card: {
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: '#dcf3ff',
        padding: 10,
        margin: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    number: {
        fontSize: 20,
    },
})