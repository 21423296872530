import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useNetInfo } from '@react-native-community/netinfo';
import Constants from 'expo-constants';

export default function OfflineNotice() {
    const netInfo = useNetInfo();

    if (netInfo.type !== 'unknown' && netInfo.isInternetReachable === false)
        return (
            <View style={styles.container}>
                <Text style={styles.text}>No Internet Connection</Text>
            </View>
        );

    return null;
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#FF004C',
        width: '100%',
        height: 50,
        position: 'absolute',
        top: Constants.statusBarHeight,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
    },
    text: {
        fontSize: 18,
        color: 'white'
    },
})