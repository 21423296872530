import { useEffect } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function Logout() {
    const { logOut } = useAuth();

    const domain = server["domain"];

    useEffect(() => {
        logOut();
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.image} source={{uri: domain + '/images/common/tree.png'}} />
            </View>
            <View style={styles.right}>
                <Text style={styles.chinese}>您已安全退出。</Text>
                <Text style={styles.english}>You've successfully logged off.</Text>
            </View> 
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#dcfaf9',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    image: {
        width: 400,
        height: 565
    },
    chinese: {
        fontFamily: 'STXinwei',
        fontSize: 70,
        marginBottom: 20
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 40,
    }
})