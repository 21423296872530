import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Price from './Price';
import server from '../config/server';

export default function ProductCard({ product }) {
    const domain = server['domain'];
    const { pid, price, quantity } = product;

    return (
        <View style={styles.container}>
            <Image style={styles.image} source={{uri: domain + '/images/shop/'+ pid + '/1.jpg'}} />
            <Price price={price} size={16} />
            <Text style={styles.quan}>x{quantity}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 10
    },
    image: {
        width: '18vh',
        height: '18vh',
        marginTop: 0,
        marginBottom: 10
    },
    quan: {
        fontSize: 18
    },
})