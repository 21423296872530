import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import TextButton from './TextButton';
import FitableImage from './FitableImage';

import { Audio } from 'expo-av';
import { LessonContext } from './Context';

import api from '../api/client';
import server from '../config/server';

export default function Paragraph({ navigation, route }) {
    const { textID } = route.params;
    const lessonID = React.useContext(LessonContext);
    const domain = server['domain'];

    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [paragraph, setParagraph] = useState(null);

    const [paused, setPaused] = useState(false);
    const [finished, setFinished] = useState(false);

    const audio = React.useRef(new Audio.Sound());

    const fetchData = async() => {
        const { data } = await api.get('/Lesson/Paragraphs/' + textID);
        setData(data);
    }

    const updateData = () => {
        setFinished(false);

        let p = data[index];
        setParagraph(p);

        const { hsk, tid, duration } = p;
        loadAudio(hsk, tid, duration);
    }

    const loadAudio = async (hsk, tid) => {
        audio.current.unloadAsync();

        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/text/HSK' + hsk + '/'+ tid + '/' + (index+1) + '.mp3'});
        audio.current = sound;

        playAudio();
    }

    const playAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && !status.isPlaying)
                audio.current.playAsync();
        } catch (error) {}

        setInterval(() => {
            checkStatus();
        }, 3000);
    }

    const replayAudio = () => {
        audio.current.replayAsync();
        setPaused(false);
    }
    
    const pauseAudio = async () => {
        try {
            const status = await audio.current.getStatusAsync();
            if (status.isLoaded && status.isPlaying)
                audio.current.pauseAsync();
        } catch (error) {}
    }

    const handlePrevious = () => {
        if (index > 0)
            setIndex(index - 1);
    }

    const handleNext = () => {
        if (index < data.length - 1)
            setIndex(index + 1);
    }

    useEffect(() => {
        fetchData();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);
    
    useEffect(() => {
        if (data.length < 1) return;

        if (index < data.length)
            updateData();
        else
            navigation.navigate("HSKTexts", { lessonID });
    }, [index, data]);

    useEffect(() => {
        if (finished && !paused) {
            setIndex(index + 1);
            return;
        }   
        if (!finished && paused) {
            pauseAudio();
            return;
        }
        if (!finished && !paused) 
        {
            playAudio();
            return;
        }
    }, [paused, finished]);

    const checkStatus = async () => {
        const status = await audio.current.getStatusAsync();
        if (status.didJustFinish)
            setFinished(true);
    }

    return (
        paragraph &&
        <View style={styles.container}>
            <View style={styles.row}>
                {paragraph.imageUrl.length > 0 &&
                <FitableImage url={paragraph.imageUrl} width={paragraph.width} height={paragraph.height} />}
                <Text style={styles.text}>{paragraph.chinese}</Text>
                <Text>  [{paragraph.pid}]</Text>
            </View>
            <View style={{flex: 1}}>
                <TextButton paused={paused} onPause={() => setPaused(true)} onPlay={() => setPaused(false)} onReplay={() => replayAudio()}
                    onPrevious={() => handlePrevious()} onNext={() => handleNext()} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
    },
    row: {
        flex: 9,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: 50,
    },
    card: {
        backgroundColor: 'white',
        padding: 10,
        borderWidth: 1,
        borderColor: 'lightgray',
        marginLeft: -20,
        marginRight: 30
    },
    image: {
        resizeMode: 'contain',
        marginRight: 50
    },
    text: {
        fontFamily: 'STZhongsong',
        fontSize: 45,
        overflow: 'hidden',
        lineHeight: 80,
        letterSpacing: 3
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
})