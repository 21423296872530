import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import OfflineNotice from './app/components/OfflineNotice';
import Navigator from './app/navigation/NavigationStack';

import { AuthContext } from './app/components/Context';
import storage from './app/api/storage';

export default function App() {
    const [user, setUser] = useState();

    const restoreUser = async () => {
        const user = await storage.getUser();
        if (user) setUser();
    }

    useEffect(() => {
        restoreUser();
    }, []);

    return (
        <AuthContext.Provider value={{user, setUser}}>
            <OfflineNotice />
            <NavigationContainer>
                <Navigator />
            </NavigationContainer>
        </AuthContext.Provider>
    );
}