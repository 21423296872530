import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import { useFormikContext } from 'formik';

export default function FormBox({name, ...otherProps}) {
    const { handleChange, errors, touched, setFieldTouched } = useFormikContext();

    return (
        <View style={styles.container}>
            <TextInput style={styles.box}
                multiline
                numberOfLines={10}
                onChangeText={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
            {...otherProps} autoCompleteType="off" />
            {touched[name] && <Text style={styles.error}>{errors[name]}</Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 20,
    },
    box: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#9af2fe',
        borderColor: 'black',
        borderWidth: 5,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Candara',
        fontSize: 20,
        padding: 10,
    },
    error: {
        fontFamily: 'Candara',
        fontSize: 18,
        color: '#FF004C',
        marginTop: 5,
        alignSelf: 'center'
    },
})