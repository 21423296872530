import React, { useState } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import Price from './Price';

export default function ShippingSelect({ data, onSelect }) {

    const [shippingID, setShippingID] = useState(2);

    const renderItem = ({item}) => {
        const { sid, name, cost, days } = item;
        let style = {};
        if (sid == shippingID)
            style = { borderColor: '#FE9600' };

        return (
            <Pressable onPress={() => { onSelect(sid, cost); setShippingID(sid) }} style={[styles.card, style]}>
                <View style={{flex: 3, flexDirection: 'column', alignItems: 'flex-start', marginHorizontal: 20}}>
                    <Text style={styles.name}>{name}</Text>
                    <Price price={cost} />
                </View>
                <View style={{flex: 1, flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center'}}>
                    <Text style={{fontSize: 25, marginRight: 10}}>{days}</Text>
                    <Text style={styles.name}>days</Text>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            <FlatList
                data={data}
                numColumns={2}
                keyExtractor={(item, index) => index}
                renderItem={renderItem}
            ></FlatList>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    card: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: 15,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderColor: '#D0ECE7'
    },
    name: {
        fontSize: 20,
        fontFamily: 'Candara',
    },
    price: {
        fontSize: 20,
        fontFamily: 'TimesNewRoman'
    }
})