import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import ProverbList from './ProverbList';
import Pagination from './Pagination';

import api from '../api/client';

export default function ProverbManager() {

    const [total, setTotal] = useState(0);
    const [pageID, setPageID] = useState(1);
    const [cID, setCID] = useState(1);
    const [english, setEnglish] = useState('');

    const pageSize = 10;

    const fetchTotal = async () => {
        const { data: total } = await api.get('/Advanced/Proverb/Total');
        setTotal(total);
    }

    const updateData = async () => {
        const { data } = await api.get('/Advanced/Proverb/' + cID);
        setEnglish(data);
    }

    useEffect(() => {
        fetchTotal();
    }, []);

    useEffect(() => {
        updateData();
    }, [cID]);

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                {total > 0 && <Pagination total={total} pageSize={pageSize} onChange={pageID => setPageID(pageID)} />}
                <ProverbList pageID={pageID} pageSize={pageSize} onSelect={cid => setCID(cid)} />
            </View>
            <View style={styles.right}>
                <Text style={{fontFamily: 'MicrosoftBlack', fontSize: 20}}>翻译</Text>
                <Text style={styles.english}>{english}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '70vw'
    },
    right: {
        width: '16vw',
        padding: 20
    },
    english: {
        fontFamily: 'Calibri',
        fontSize: 22
    }
})