import React from 'react';
import { View, Text, Modal, Pressable, StyleSheet } from 'react-native';

export default function DeleteCancel({ visible, onDelete, onCancel }) {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
        >
            <View style={styles.m1}>
                <View style={styles.m2}>
                    <Pressable onPress={() => onDelete()} style={[styles.button, {borderBottomColor: 'lightgray', borderBottomWidth: 1}]}>
                        <Text style={styles.text}>Delete</Text>
                    </Pressable>
                    <Pressable onPress={() => onCancel()} style={styles.button}>
                        <Text style={styles.text}>Cancel</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    m1: {
        width: '70%',
        justifyContent: "center",
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
        bottom: 20
    },
    m2: {
        width: '70%',
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.3)',
    },
    button: {
        width: '100%',
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18
    }
})