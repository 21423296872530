import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import HSKText from '../components/HSKText';
import HSKTexts from '../components/HSKTexts';
import Dialog from '../components/Dialog';
import Paragraph from '../components/Paragraph';

const Stack = createNativeStackNavigator();

const TextNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="HSKTexts" component={HSKTexts} options={{ headerShown: false }} />
            <Stack.Screen name="HSKText" component={HSKText} options={{ headerShown: false }} />
            <Stack.Screen name="Dialog" component={Dialog} options={{ headerShown: false }} />
            <Stack.Screen name="Paragraph" component={Paragraph} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default TextNavigator;