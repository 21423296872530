import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import MemberOrder from './MemberOrder';
import ProductOrder from './ProductOrder';

import useAuth from '../hooks/useAuth';

export default function Transaction() {
    const { user } = useAuth();
    const [showMember, setShowMember] = useState(true);

    const selectedStyle = {
        borderBottomWidth: 2,
        borderBottomColor: 'orange'
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Pressable onPress={() => setShowMember(true)}>
                    <View style={[styles.button, showMember? selectedStyle : null]}>
                        <Text style={styles.text}>Member Orders</Text>
                    </View>                        
                </Pressable>
                <Pressable onPress={() => setShowMember(false)} style={{marginLeft: 50}}>
                    <View style={[styles.button, !showMember? selectedStyle : null]}>
                        <Text style={styles.text}>Product Orders</Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.r2}> 
                {showMember? < MemberOrder personID={user.personID} /> :
                <ProductOrder personID={user.personID} />}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 8
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 10,
    },
    r2: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        ontFamily: 'Candara',
        fontSize: 20,
        margin: 10
    },
})