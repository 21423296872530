import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Price from './Price';

export default function CheckoutTotal({ sum, shippingFee, credit, count, total }) {
    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.left}>
                    <Text style={styles.text}>{count} Products</Text>
                </View>
                <View style={styles.right}>
                    <Price price={sum} />
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.left}>
                    <Text style={styles.text}>Shipping Fee</Text>
                </View>
                <View style={styles.right}>
                    <Price price={shippingFee} />
                </View>
            </View>
            {credit > 0 && <View style={styles.row}>
                <View style={styles.left}>
                    <Text style={styles.text}>Use Credit</Text>
                </View>
                <View style={styles.right}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={[styles.text, {color: '#FF004C'}]}>- </Text>
                        <Price price={credit} color="#FF004C" />
                    </View>
                </View>
            </View>}
            <View style={styles.row}>
                <View style={styles.left}>
                    <Text style={styles.text}>Total</Text>
                </View>
                <View style={styles.right}>
                    <Price price={total} />
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#D0ECE7',
        paddingVertical: 15,
        paddingHorizontal: 20
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingHorizontal: 20
    },
    left: {
        flex: 1,
        alignItems: 'flex-start',
    },
    right: {
        flex: 1,
        alignItems: 'flex-end'
    },
    text: {
        fontSize: 18,
    },
})