import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';
import AddressCard from './AddressCard';
import AddressForm from './AddressForm';
import MyButton from './form/MyButton';
import DeleteCancel from './DeleteCancel';
import Popup from './Popup';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function AddressBook({ navigation }) {

    const { user } = useAuth();

    const [data, setData] = useState([]);       //address list
    const [showForm, setShowForm] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [addressID, setAddressID] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (showForm) setOpacity(0.4);
        else setOpacity(1);
    }, [showForm]);

    const fetchData = async () => {
        const { data: list } = await api.get('/Address/Person/' + user.personID);
        setData(list);

        if (list.length == 0)
            setShowForm(true);
    }

    const handleDelete = async () => {
        await api.put('/Address/Delete/' + addressID);
        fetchData();
        setShowButtons(false);
    }

    const handleSave = () => {
        fetchData();
        setShowForm(false);
    }

    const handleSelect = (aid) => {
        if (navigation != undefined)
            navigation.navigate("Checkout", { addressID : aid });
    }

    const setDefault = async (aid) => {
        await api.put('/Address/Default/' + aid);
        fetchData();
    }

    const renderItem = ({item}) => {
        const { aid } = item;
        return (
            <Pressable style={styles.card} onPress={() => handleSelect(aid)}>
                <View style={{alignItems: 'flex-end', height: 25, paddingRight: 20}}>
                    {data.length > 1 &&
                    <Pressable onPress={() => {setShowButtons(true); setAddressID(aid)}}>
                        <AntDesign name='delete' color='black' size={25} />
                    </Pressable>}
                </View>
                <AddressCard address={item} />
                <View style={{alignItems: 'flex-end', height: 25, paddingRight: 20}}>
                    {item.isDefault?
                    <View style={styles.rowDef}>
                        <AntDesign name='checkcircle' color='#4CBB17' size={25} />
                        <Text style={styles.txtDef}>Default</Text>
                    </View>:
                    <View style={styles.rowDef}>
                        <Pressable  onPress={() => setDefault(aid)}>
                            <Entypo name='circle' color='lightgray' size={25} />
                        </Pressable>
                        <Text style={styles.txtDef}>Default</Text>
                    </View>}
                </View>
            </Pressable>
        );
    }

    return (
        <View style={[styles.container,{ opacity: opacity }]}>
            <View style={{width: '70%', position: 'relative'}}>
                {data.length > 0 &&
                <FlatList
                    data={data}
                    keyExtractor={(item, index) => index}
                    renderItem={renderItem}
                ></FlatList>}
                {data.length < 3 &&
                <View style={{marginTop: 20}}>
                    <MyButton text="Add New Address" color="black" onPress={() => setShowForm(true)} />
                </View>}
         
                <Popup show={showForm} onClose={() => setShowForm(false)}>
                    <AddressForm onSave={() => handleSave()} />
                </Popup>

                <DeleteCancel visible={showButtons} onDelete={() => handleDelete()} onCancel={() => setShowButtons(false)}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingVertical: 20
    },
    vBtn: {
        marginVertical: 20
    },
    card: {
        flex: 1,
        backgroundColor: 'white',
        padding: 10,
        marginVertical: 10,
    },
    rowDef: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    txtDef: {
        fontSize: 20,
        marginLeft: 10
    },
})