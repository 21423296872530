import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

//import AdsVideo from '../components/AdsVideo';
import SampleDialog from '../components/SampleDialog';

const Stack = createNativeStackNavigator();

const SampleNavigator = () => {
    return (
        <Stack.Navigator>
            {/* <Stack.Screen name="AdsVideo" component={ AdsVideo } options={{ headerShown: false }} /> */}
            <Stack.Screen name="Sample" component={ SampleDialog } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default SampleNavigator;