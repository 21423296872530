import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from '../components/Login';
import Register from '../components/Register';
import PassForgot from '../components/PassForgot';
import VocabularyNavigator from './VocabularyNavigator';

const Stack = createNativeStackNavigator();

const AuthNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Login" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>
            <Stack.Screen name="VocabularyNavigator" component={ VocabularyNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="PassForgot" component={PassForgot} options={{ headerShown: false }} />
            <Stack.Screen name="Register" component={Register} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default AuthNavigator;