export default {
    hsk1: '#F0E130',
    hsk2: '#4CBB17',
    hsk3: '#FE9600',
    hsk4: '#FF004C',
    hsk5: '#00BFFF',
    hsk6: '#A020F0',

    noun: '#A2D9CE',
    verb: '#00BFFF',
    adjective: '#FF759C',
    adverb: '#DCFAF9',
    pronoun: '#FCF3CF',
    numeral: '#AEB6BF',
    quantifier: '#A2D9CE',
    preposition: '#A9CCE3',
    conjunction: '#ABEBC6',
    interjection: '#E6B0AA',
    particle: '#D6DBDF',
    mod: '#DAF7A6',
    All: '#000'
}