import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { LessonContext } from '../components/Context';

import TextNavigator from './TextNavigator';
import HSKVocabulary from '../components/HSKVocabulary';

const Tab = createMaterialTopTabNavigator();

const LessonTabs = ({ route }) => {
    return (
      <LessonContext.Provider value={route.params.lesson}>
        <Tab.Navigator>
          <Tab.Screen name="TextNavigator" component={ TextNavigator }
              options={{
                tabBarLabel:({ focused })=>(
                  <Text style={[styles.label, focused && {color:'#4CBB17',}]}>Text</Text>),
              }}/>
          <Tab.Screen name="HSKVocabulary" component={ HSKVocabulary }
              options={{
                tabBarLabel:({ focused })=>(
                  <Text style={[styles.label, focused && {color:'#4CBB17'}]}>Vocabulary</Text>)
              }}/>
        </Tab.Navigator>
      </LessonContext.Provider>
    );
}

const styles = StyleSheet.create({
  label: {
    fontFamily: 'Comic Sans MS',
    fontSize: 18,
  },
})

export default LessonTabs;