import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Form, FormLine, SubmitButton } from './form';
import { Dropdown } from 'react-native-element-dropdown';

import * as Yup from 'yup';
import api from '../api/client';
import useAuth from '../hooks/useAuth';

const schema = Yup.object().shape({
    firstName: Yup.string().required().min(2).max(30).label("First name)"),
    lastName: Yup.string().required().min(2).max(30).label("Last name"),

    address1: Yup.string().required().min(2).max(50).label("Address 1"),
    address2: Yup.string().required().min(2).max(50).label("Address 2"),

    city: Yup.string().required().label("City"),
    state: Yup.string().label("State / Province / Region"),

    zipCode: Yup.string().required().label("Zip Code"),
    phone: Yup.string().required().min(6).label("Phone number"),
})

export default function AddressForm({ onSave }) {

    const { user } = useAuth();

    const [data, setData] = useState([]);           //country list
    const [countryID, setCountryID] = useState(0);  //selected country ID
    const [countryCode, setCountryCode] = useState('');
    const [errorStyle, setErrorStyle] = useState(null);

    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        address1: '', 
        address2: '', 
        city: '', 
        state1: '', 
        zipCode: '', 
        phone: ''
    }

    useEffect(() => {
        fetchCountry();
    }, []);

    const fetchCountry = async () => {
        const { data } = await api.get('/Address/Country/');
        setData(data);
    }

    const saveAddress = async (values) => {

        if (countryID == 0) {
            setErrorStyle({borderColor: '#FF004C'});
            return;
        } else {
            setErrorStyle(null);
        }
  
        const {firstName, lastName, address1, address2, city, state1, zipCode, phone} = values;

        let newState = state1.length == 0? "No" : state1;

        const ownerID = user.personID;
    
        const { data } = await api.get("/Address/" + ownerID + "/" + firstName + "/" + lastName + "/"
            + address1 + "/" + address2 + "/" + city + "/" + newState + "/" + countryID + "/" + zipCode + "/" + phone);

        if (data === 1)
            onSave();
    }

    const handleChange = (item) => {
        const { cid, countryCode } = item;
        setCountryID(cid);
        setCountryCode('+' + countryCode);

        if (cid > 0)
            setErrorStyle(null);
    }

    return (
        <View style={styles.container}>
            <Text style={[styles.header, {marginVertical: 15}]}>Delivery Address</Text>
            <View>
            <Form
                initialValues={initialValues}
                onSubmit={(values, actions) => { saveAddress(values); 
                    //  actions.resetForm({values: initial})
                }}
                validationSchema={schema}
            >
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 1, marginRight: 10}}>
                        <FormLine 
                            name="firstName"
                            placeholder={user.firstName}
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <FormLine
                            name="lastName"
                            placeholder={user.lastName}
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                </View>
                <Dropdown style={[styles.dropdown, errorStyle]} mode='modal' data={data} valueField='cID' value={0}
                    labelField='name' fontFamily='Candara' fontSize="25"
                    searchField='name' search={true}
                    selectedTextStyle={{backgroundColor: 'orange'}}
                    placeholder="Select Country"
                    onChange={item => handleChange(item)} />
                <FormLine 
                    name="address1"
                    placeholder="Apartment, suite, unit, building, floor, etc."
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    textContentType="default"
                />
                <FormLine 
                    name="address2"
                    placeholder="Street address, P.O. box, company name, c/o"
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    textContentType="default"
                />
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 1, marginRight: 10}}>
                        <FormLine 
                            name="city"
                            placeholder="City"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <FormLine 
                            name="state1"
                            placeholder="State"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                </View>
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 3, marginRight: 10}}>
                        <FormLine 
                            name="zipCode"
                            placeholder="Zip Code"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                    <View style={{ flex: 2, alignItems: 'flex-end', justifyContent: 'center', marginRight: 10 }}>
                        <Text style={{fontSize: 18, fontFamily: 'Candara'}}>{countryCode}</Text>
                    </View>
                    <View style={{flex: 5}}>
                        <FormLine 
                            name="phone"
                            placeholder="Phone Number"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="default"
                        />
                    </View>
                </View>
                <View style={{width: 600, height: 60}}>
                    <SubmitButton text="Save" color="black"/>
                </View>
            </Form>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        fontFamily: 'Candara',
        fontSize: 20,
        fontWeight: 'bold'
    },
    dropdown: {
        borderWidth: 2,
        borderRadius: 10,
        height: 60,
        paddingHorizontal: 20,
        marginBottom: 10,
        justifyContent: 'center',
      },
    form: {
        paddingVertical: 30
    },
})
