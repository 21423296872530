import { useContext } from 'react';
import { AuthContext } from '../components/Context';
//import { jwtDecode } from 'jwt-decode';
//import storage from '../api/storage';

const useAuth = () => {
    const { user, setUser } = useContext(AuthContext);

    const logIn = (authToken) => {
        setUser(authToken);
    //    storage.storeToken(authToken);
    }

    const logOut = () => {
        setUser(null);
    //    storage.removeToken();
    }

    return { user, setUser, logIn, logOut };
}

export default useAuth;