import React, { useState, useRef }  from 'react';
import { View, Text, TextInput, Button, StyleSheet } from 'react-native';
import ErrorMessage from './form/ErrorMessage';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function CouponForm({ onCheck }) {

    const { user } = useAuth();

    const [error, setError] = useState('');
    const [visible, setVisible] = useState(false);

    const [v1, setV1] = useState('');
    const [v2, setV2] = useState('');
    const [v3, setV3] = useState('');
    const [v4, setV4] = useState('');
    const [v5, setV5] = useState('');
    const [v6, setV6] = useState('');

    const max = 1;

    const v2Ref = useRef(null);
    const v3Ref = useRef(null);
    const v4Ref = useRef(null);
    const v5Ref = useRef(null);
    const v6Ref = useRef(null);
    const btnRef = useRef(null);

    const changed = async () => {
        setError('');
        const c = [v1,v2,v3,v4,v5,v6].join('');
        if (c.length !== 6)
            setVisible(false);
    }

    const checkCoupon = async () => {
        const coupon = [v1,v2,v3,v4,v5,v6].join('');
        const { data } = await api.get('/Coupon/Check/' + user.personID + '/' + coupon);

        if (data == 1)
            return onCheck(coupon);
      
        if (data === -1)
            setError('The coupon code is invalid.');
        else if (data === -2)
            setError('You cannot use your own affiliate coupon.');
        else if (data === -3)
            setError('This coupon is expired.');

        return onCheck(null);
    }

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Have you a coupon? (optional)</Text>
            <ErrorMessage text={error} />
            <View style={styles.c1}>
                <View style={styles.c2}>
                    <View style={styles.chars}>
                        <TextInput style={styles.input} value={v1} maxLength={max} autoFocus={true}
                            onChangeText={v => {
                                setV1(v.toUpperCase());
                                changed();
                                if (v.length >= max)
                                    v2Ref.current?.focus();
                            }} />
                    
                        <TextInput style={styles.input} value={v2} maxLength={max} ref={ref => (v2Ref.current = ref)}
                            onChangeText={v => {
                                setV2(v.toUpperCase());
                                changed();
                                if (v.length >= max)
                                    v3Ref.current?.focus();
                            }} />

                        <TextInput style={styles.input} value={v3} maxLength={max} ref={ref => (v3Ref.current = ref)}
                            onChangeText={v => {
                                setV3(v.toUpperCase());
                                changed();
                                if (v.length >= max)
                                    v4Ref.current?.focus();
                            }} />

                        <TextInput style={styles.input} value={v4} maxLength={max} ref={ref => (v4Ref.current = ref)}
                            onChangeText={v => {
                                setV4(v.toUpperCase());
                                changed();
                                if (v.length >= max)
                                    v5Ref.current?.focus();
                            }} />

                        <TextInput style={styles.input} value={v5} maxLength={max} ref={ref => (v5Ref.current = ref)}
                            onChangeText={v => {
                                setV5(v.toUpperCase());
                                changed();
                                if (v.length >= max)
                                    v6Ref.current?.focus();
                            }} />

                        <TextInput style={styles.input} value={v6} maxLength={max} ref={ref => (v6Ref.current = ref)}
                            onChangeText={v => {
                                setV6(v.toUpperCase());
                                changed();
                                if (v.length >= max) {
                                    setVisible(true);
                                    btnRef.current?.focus();
                                }
                            }} />
                    </View>
                </View>
            </View>
            {visible && <Button title="Apply Coupon" color="#000" onPress={() => checkCoupon()} ref={ref => (btnRef.current = ref)} />}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        paddingVertical: 20
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 28,
        alignSelf: 'flex-start',
        marginLeft: '10%',
        marginBottom: 10,
    },
    c1: {
        backgroundColor: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        height: 120,
        borderColor: '#00aef0',
        borderStyle: 'dashed',
        borderWidth: 10,
        padding: 10,
        marginTop: 10,
        marginBottom: 10
    },
    c2: {
        backgroundColor: '#00aef0',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    off: {
        fontFamily: 'Candara',
        fontSize: 40,
        color: 'white',
    },
    expire: {
        marginTop: 10,
        fontFamily: 'Comic Sans MS',
        fontSize: 20,
        color: '#FE9600'
    },
    chars: {
        flex: 1,
        flexDirection: 'row',
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        width: '10%',
        height: '80%',
        borderBottomWidth: 2,
        borderColor: 'black',
        fontSize: 60,
        marginHorizontal: 8,
        alignSelf: 'center',
    },
    btnPay: {
        width: '80%',
        height: 50,
        marginTop: 20,
        backgroundColor: '#00BFFF',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        borderRadius: 5,
    },
    payText: {
        fontFamily: 'Candara',
        color: 'white',
        fontSize: 22,
    }
})