import React, { useState } from 'react';
import { View, StyleSheet, Pressable, TextInput } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function PayPalUpdate() {

    const { user } = useAuth();
    const [paypal, setPaypal] = useState(user.paypal);

    const save = async () => {
        await api.put('/User/Paypal/' + user.personID + '/' + paypal.trim());
    }

    return (
        <View style={styles.container}>
            <TextInput style={styles.input} value={paypal} onChangeText={t => setPaypal(t)} />
            <Pressable onPress={() => save()} style={{marginLeft: 20, justifyContent: 'flex-end'}}>
                <MaterialCommunityIcons name='pen' color='green' size={25} />
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    input: {
        width: '100%',
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: 'darkgray',
        fontSize: 18,
        fontFamily: 'Comic Sans MS',
        padding: 10
    },
})