import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CheckBox from './CheckBox';
import useAuth from '../hooks/useAuth';

export default function CreditOption({ sum, onChange }) {

    const { user } = useAuth();

    const [selected, setSelected] = useState(true);
    const [credit, setCredit] = useState(0);

    useEffect(() => {
        let canUse = user.credit <= sum? user.credit : sum;
        let credit = Math.trunc(canUse);
        setCredit(credit);
        onChange(credit);
    }, [sum]);

    useEffect(() => {
        if (selected)
            onChange(credit);
        else
            onChange(0);
    }, [selected]);

    if (credit < 1)
    return null;

    return (
        <View style={styles.container}>
            <Text style={styles.text}>Use $</Text>
            <Text style={{fontFamily: 'verdana', fontSize: 20}}>{credit}</Text>
            <Text style={styles.text}> credit?</Text>
            <View style={{flex: 1, alignItems: 'flex-end'}}>
                <CheckBox value={selected} onValueChange={() => setSelected(!selected)} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#D4E6F1',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'dashed 2px #000',
        paddingVertical: 12,
        paddingHorizontal: 20,
        marginTop: 8
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 20,
    },
    box: {
        width: 30,
        height: 30,
        alignSelf: 'flex-end'
    },
})