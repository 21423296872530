import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import server from '../config/server';

export default function PaymentSelect() {
    const domain = server["domain"];
    return (
        <View style={styles.container}>
            <Text style={styles.text}>Payment Method</Text>
            <View style={styles.vImage}>
                <Image style={styles.image} source={{uri: domain + '/images/common/paypal.jpg'}} />
            </View> 
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'darkgray',
        padding: 20
    },
    text: {
        fontSize: 20,
    },
    vImage: {
        flex: 1,
        alignItems: 'flex-end',
    },
    image: {
        width: 34,
        height: 33,
    }
})