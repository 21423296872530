import { useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import api from '../api/client';
import useAuth from "../hooks/useAuth";

const style = {"layout":"vertical"};

export const ButtonWrapper2 = ({ id, amount, credit, shippingID, addressID, showSpinner }) => { // for product order

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const [orderID, setOrderID] = useState(id == undefined? null : id);
    const [paypalID, setPaypalID] = useState(null);

    const { user, setUser } = useAuth();

    const createOrder = async (paypalID) => {
        console.log('paypalID: ' + paypalID);
        setPaypalID(paypalID);

        if (orderID != null)
            return;

        const { data } = await api.put('/Order/' + user.personID + '/' + amount + '/' + addressID + '/' + shippingID + '/' + credit);
        setOrderID(data);

        let c = user.credit - credit;
        let u = { ...user, credit: c };
        setUser(u);
    }

    const updateOrder = async () => {
        const { data } = await api.put('/Order/' + orderID + '/' + paypalID);
        if (data == 1)
            navigation.navigate("Receipt", { orderID: oID, paypalID: paypalID });
    }

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {...options},
        });
    }, [showSpinner]);

    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, 'USD', style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "USD",
                                        value: amount,
                                    },
                                },
                            ],
                            application_context: {
                                shipping_preference: "NO_SHIPPING"
                            }
                        })
                        .then((orderId) => {
                            createOrder(orderId);
                            return orderId;
                        });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then(function () {
                        updateOrder();
                    });
                }}
            />
        </>
    );
}