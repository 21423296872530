import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import Sentence from './Sentence';
import PauseButton from './PauseButton';

import api from '../api/client';

export default function Sentences({ textID, pageID, newWords, onFinish }) {

    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);

    const [paused, setPaused] = useState(false);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        fetchData();
        return () => controller?.abort();
    }, [pageID]);
    
    const fetchData = async () => {
        const { data } = await api.get('/Dialog/' + textID + '/' + pageID);
        setData(data);
        setIndex(0);
   }

    useEffect(() => {
        if (finished && !paused) {
            setFinished(false);
            if (index + 1 < data.length)
                setIndex(index + 1);
            else {
                setIndex(-1);
                onFinish();
            }
        }
    }, [paused, finished]);

    return (
        <View style={styles.container}>
            {index >= 0 && data[0] && <Sentence data={data[0]} newWords={newWords} onFinish={() => setFinished(true)} />}
            {index >= 1 && data[1] && <Sentence data={data[1]} newWords={newWords} onFinish={() => setFinished(true)} />}
            {index >= 2 && data[2] && <Sentence data={data[2]} newWords={newWords} onFinish={() => setFinished(true)} />}
            <PauseButton paused={paused} onPause={() => setPaused(true)} onPlay={() => setPaused(false)} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingHorizontal: 10,
        paddingVertical: 20
    }
})