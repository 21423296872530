import React, { useState, useEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import BookCard from './BookCard';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function BookMenu({ navigation }) {
    const { user } = useAuth();
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const { data } = await api.get('/Book/');
        setData(data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelect = async (bID, isFree) => {
        if (isFree || user && user.isMember)
            return navigation.navigate("Book", { BID: bID });

        if (user == undefined)
            return navigation.navigate("Login7", { prevScreen: 'BookMenu'});
        
        if (!user.isMember)
            return navigation.navigate("Plan7");  
    }

    return (
        <View style={styles.container}>
            <ScrollView>
                <View style={styles.list}>
                    {data.map(item =>
                        <View key={item.bid}>
                            <BookCard data={item} onPress={() => handleSelect(item.bid, item.isFree)} />
                        </View>
                )}
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
})