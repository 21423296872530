import React, { useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';

import UserInfo from './UserInfo';
import Credit from './Credit';
import AddressBook from './AddressBook';
import Transaction from './Transaction';
import PassChange from './PassChange';

export default function Account() {
    const [select, setSelect] = useState('UserInfo');

    return (
        <View style={styles.container}>
            <View style={{width: '95%'}}>
                {select === 'UserInfo' &&
                    <View style={styles.row}>
                        <View style={styles.userInfo}>
                            <UserInfo />
                        </View>
                        <View style={styles.credit}>
                            <Credit />
                        </View>
                    </View>}
                {select === 'Orders' &&
                    <View style={styles.row}>
                        <View style={styles.transaction}>
                            <Transaction />
                        </View>
                    </View>}
                {select === 'Address' &&
                    <View style={styles.row}>
                        <View style={styles.transaction}>
                            <AddressBook />
                        </View>
                    </View>}
                {select === 'Pass' &&
                    <View style={styles.row}>
                        <View style={styles.pass}>
                            <PassChange />
                        </View>
                    </View>}
            </View>
            <View style={{flex: 1, width: '5%', flexDirection: 'column', marginLeft: 5}}>
                <TouchableOpacity onPress={() => setSelect('UserInfo')} style={[styles.tab, {backgroundColor: "#FAD7A0"}]}>
                    <Text>User{'\n'}Info</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setSelect('Orders')} style={[styles.tab, {backgroundColor: "#D6EAF8"}]}>
                    <Text>Orders</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setSelect('Address')} style={[styles.tab, {backgroundColor: "#D7BDE2"}]}>
                    <Text>Address{'\n'}Book</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setSelect("Pass")} style={[styles.tab, {backgroundColor: "#c8fe8c"}]}>
                    <Text>Change{'\n'}Password</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    tab: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomColor: '606060',
        borderBottomWidth: 2,
      },
    row: {
        flex: 1,
        height: '48%',
        flexDirection: 'row',
    },
    userInfo: {
        flex: 2,
    },
    credit: {
        flex: 1,
        marginLeft: 10
    },
    setting: {
        flex: 1,
        marginLeft: 10
    },
    transaction: {
        flex: 2,
        marginTop: 10,
    },
    pass: {
        flex: 1,
        marginLeft: 10,
        marginTop: 10,
    },
})