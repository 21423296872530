import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import ProductInfo from './ProductInfo';
import server from '../config/server';

export default function ProductRow({ product }) {
    const { pid, brand, name, optName, price, quantity } = product;
    const domain = server['domain'];

    return (
        <View style={styles.container}>
            <View style={styles.vImage}>
                <Image style={styles.image} source={{uri: domain + '/images/shop/' + pid + '/1.jpg'}} />
            </View>
            <View style={{width:'50%'}}>
                <ProductInfo brand={brand} name={name} option={optName} price={price} />
            </View>
            <View style={styles.vQuan}>
                <Text style={styles.text}>x {quantity}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 10
    },
    vImage: {
        width: '30%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: '18vh',
        height: '18vh',
        resizeMode: 'contain',
    },
    vQuan: {
        width: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: 'Verdana',
        fontSize: 22
    }
})