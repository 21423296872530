import React, { useState, useEffect } from 'react';
import { View, Text, Image, FlatList, Pressable, StyleSheet } from 'react-native';
import LessonTitle from './LessonTitle';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';
import color from '../config/color';

import { LessonContext } from './Context';

export default function HSKTexts({ navigation }) {

    const lesson = React.useContext(LessonContext);
    const domain = server['domain'];

    const [data, setData] = useState([]); //One lesson could have 1 to 5 texts;
    const [played, setPlayed] = useState(false);
    const [width, setWidth] = useState(250);

    const audio = React.useRef(new Audio.Sound());

    const c = color['hsk' + lesson.hsk];

    useEffect(() => {
        fetchData();
        playLesson();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);

    const updateSize = async () => {
        const { data: size } = await api.get('/Lesson/ImageSize/' + lesson.lid);
        let width = size.item1;
        let height = size.item2;
        if (width > 500) {
            width = width / 2;
            height = height / 2;
        }
        setStyle({width: width, height: height});
    }

    const fetchData = async () => {
        const { data } = await api.get('/Lesson/Texts/' + lesson.lid);
        setData(data);
        
        if (data.length > 4)
            setWidth('15vw');
        else if (data.length < 4)
            setWidth('20vw');
    }

    const playLesson = async () => {        
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/lesson/'+ lesson.lid + '.mp3'});
        audio.current = sound;
        audio.current.playAsync();

        setTimeout(() => {
            setPlayed(true);
        }, lesson.duration * 1000);
    }

    const renderItem = ({ item }) => {
        return (
            <Pressable onPress={() => navigation.navigate("HSKText", { text: item })}>
                <View style={[styles.card, {borderColor: c, width: width}]}>
                    <View style={{flex: 1}}>
                        <Text style={styles.textID}>{item.textID}</Text>
                    </View>
                    <View style={{flex: 4, justifyContent: 'center'}}>
                        <Text style={styles.chinese}>{item.chinese}</Text>
                    </View>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            <LessonTitle lessonID={lesson.lessonID} chinese={lesson.chinese} english={lesson.english} />
            <View style={styles.container2}>
                {played?
                <FlatList
                    data={data}
                    keyExtractor={item => item.tid}
                    renderItem={renderItem}
                    horizontal={true}
                ></FlatList>:
                <View style={styles.imageV}>
                    <Image style={styles.image} source={{uri: domain + '/images/lesson/' + lesson.lid + '.jpg'}} />
                </View>
                }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#EBF5FB',
    },
    container2: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageV: {
        backgroundColor: 'white',
        padding: 10,
        marginRight: 30,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        width: '50vh',
        height: '50vh',
        resizeMode: 'contain',
    },
    card: {
        backgroundColor: '#ffffff',
        borderWidth: 8,
        borderRadius: 10,
        width: 250,
        height: 300,
        padding: 20,
        marginHorizontal: 15,
        alignItems: 'center',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.3)',
    },
    textID: {
        fontSize: 40,
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
    },
})