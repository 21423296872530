import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function DeductionTable({ original, coupon, credit }) {
    const c = coupon == null? 0 : 20;

    if (coupon == null && credit <= 0)
    return (<></>)

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.text}>Original price: </Text>
                </View>
                <View style={styles.col2}>
                    <Text style={styles.price}>$ {original}</Text>
                </View> 
            </View>
            {coupon && <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.text}>Coupon deduction: </Text>
                </View>
                <View style={styles.col2}>
                    <Text style={[styles.price, {color: '#FF004C'}]}>- $ {c}</Text>
                </View> 
            </View>}
            {credit > 0 && <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.text}>Use Credit: </Text>
                </View>
                <View style={styles.col2}>
                    <Text style={[styles.price, {color: '#FF004C'}]}>- $ {credit}</Text>
                </View> 
            </View>}
            <View style={[styles.row, { borderBottomWidth: 0 }]}>
                <View style={styles.col1}>
                    <Text style={styles.total}>Total: </Text>
                </View>
                <View style={styles.col2}>
                    <Text style={styles.total}>$ {original - c - credit}</Text>
                </View> 
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderWidth: 3,
        borderColor: '#00aef0',
        width: '100%',
        padding: 10,
    },  
    row: {
        flex: 1,
        flexDirection: 'row',
        borderBottomColor: '#00aef0',
        borderBottomWidth: 2
    },
    col1: {
        width: '60%',
        padding: 10,
        justifyContent: 'center'
    },
    col2: {
        width: '40%',
        padding: 10,
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 18
    },
    price: {
        fontFamily: 'Candara',
        fontSize: 20
    },
    total: {
        fontFamily: 'Candara',
        fontWeight: 'bold',
        fontSize: 22
    },
})