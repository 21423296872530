import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function LessonTitle({ lessonID, chinese, english }) {
    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Text style={styles.chinese}>第{lessonID}课</Text>
                <Text style={styles.english}>Lesson {lessonID}</Text>
            </View>
            <View style={styles.right}>
                <Text style={styles.chinese}>{chinese}</Text>
                <Text style={styles.english}>{english}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 30,
        flexDirection: 'row',
        paddingHorizontal: 30
    },
    left: {
        marginRight: 50,
        alignItems: 'center',
    },
    right: {
        alignItems: 'center',
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 50,
        letterSpacing: 5
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 30,
    },
})