import React from 'react';
import { View, Image, Pressable, StyleSheet } from 'react-native';
import server from '../config/server';

export default function LinkImage({ url, cat, visible, onPress }) {

    const style = visible? {visibility: 'visible'} : {visibility: 'hidden'};
    const domain = server["domain"];

    return (
        <Pressable onPress={onPress}>
            <View style={[styles.card, style]}>
                <Image style={styles.image} source={{uri: domain + '/images/vocab/'+ cat +'/' + url + '.jpg'}} />
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    card: {
        width: '14vh',
        height: '14vh',
        backgroundColor: '#fff',
        borderWidth: 5,
        borderColor: '#00BFFF'
    },
    image: {
        resizeMode: 'contain',
        width: '100%',
        height: '100%',
    },
})