import React, { useState }  from 'react';
import { View, Image, TouchableHighlight, StyleSheet, Platform } from 'react-native';
//import ImagePicker  from 'react-native-image-crop-picker';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';

import api from '../api/client';
import server from '../config/server';

export default function Photo({ personID, male }) {

    const [uri, setUri] = useState(null);
    const domain = server['domain'];

    const [showMenu, setShowMenu] = useState(false);

    const options = {
        mediaType: 'photo',
        includeBase64: false,
        maxHeight: 2000,
        maxWidth: 2000,
    };

    // const pickImage = async () => {
    //     ImagePicker.openPicker({
    //         width: 300,
    //         height: 330,
    //         cropping: true,
    //     }).then(image => {
    //         console.log('image:', image);
    //         saveImage(image);
    //     });
    // }

    const pickImage = () => {
        launchImageLibrary(options, response => {
            if (response.didCancel) {
                console.log('User cancelled image picker');
            } else if (response.error) {
                console.log('Image picker error: ', response.error);
            } else {
                let imageUri = response.uri || response.assets?.[0]?.uri;
               // console.log('response:', response.assets[0].uri);
                setUri(imageUri);
                saveImage(response.assets[0]);
            }
        });
    }

    const saveImage = async (file) => {
     //   let name = file.uri.split('/').pop();

        const body = new FormData();
        body.append('PID', personID);
        body.append('file', {
            uri: Platform.OS === "android"? file.uri : file.uri.replace("file://", ""),
          //  name: name,
            type: 'image/jpg'
        });

        if (!file.cancelled) {
            await api.post("/User/Upload", body);
            setUri(file.uri);
        }
    }

    return (
        <View style={styles.container}>
            { uri? <TouchableHighlight onPress={() => pickImage2()}>
                <Image style={styles.photo} source={{ uri: uri }} />
            </TouchableHighlight> :
            <TouchableHighlight onPress={() => pickImage()}>
                {male? <Image style={styles.photo} source={{uri: domain + '/images/common/male.jpg'}} />:
                    <Image style={styles.photo} source={{uri: domain + '/images/common/female.jpg'}} />}
            </TouchableHighlight>
            }
        </View>   
    );
}

const styles = StyleSheet.create({
    container: {
        width: 240,
        height: 260,
        padding: 20,
        backgroundColor: 'white',
        borderStyle: 'dashed',
        borderWidth: 5,
        borderColor: 'gray',
        justifyContent: 'center',
        alignItems: 'center'
    },
    photo: {
        resizeMode: 'contain',
        width: 200,
        height: 220,
    },
})