import React, { useState, useEffect } from 'react';
import { View, Text, Image, Pressable, StyleSheet } from 'react-native';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';

export default function Saying({ navigation, route }) {

    const { cid } = route.params;

    const domain = server['domain'];

    const [chinese, setChinese] = useState('');
    const [english, setEnglish] = useState('');
    const [duration, setDuration] = useState(5);

    const audio = React.useRef(new Audio.Sound());

    useEffect(() => {
        fetchData();
        playSound();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Advanced/Saying/' + cid);
        const { chinese, english, duration } = data;

        setChinese(chinese);
        setEnglish(english);
        setDuration(duration);
    }

    const playSound = async () => {        
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/saying/'+ cid + '/0.mp3'});
        audio.current = sound;
        audio.current.playAsync();

        setTimeout(() => {
            navigation.navigate("SayingText", { textID: cid });
        }, (duration + 1) * 1000);
    }

    return (
        <View style={styles.container}>
            <View style={styles.top}>
                <Text style={styles.chinese}>{chinese}</Text>
                <Text style={styles.english}>{english}</Text>
            </View>
            <View style={styles.imageV}>
                <Pressable>
                    <Image style={styles.image} source={{uri: domain + '/images/saying/' + cid + '.jpg'}} />
                </Pressable>
            </View>
        </View>

        // <View style={styles.container}>
        //     <View style={styles.top}>
        //         <Text style={styles.chinese}>{chinese}</Text>
        //         <Text style={styles.english}>{english}</Text>
        //     </View>
        //     <View style={styles.imageV}>
        //         <Pressable>
        //             <Image style={styles.image} source={{uri: domain + '/images/saying/' + cid + '.jpg'}} />
        //         </Pressable>
        //     </View>
        // </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#EBF5FB',
    },
    top: {
        marginVertical: 60,
        alignItems: 'center'
    },
    imageV: {
        backgroundColor: 'white',
        padding: 10,
        marginRight: 30,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        width: '50vh',
        height: '50vh',
        resizeMode: 'contain',
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 50,
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 35,
    }
})