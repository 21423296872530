import React from 'react';
import { Text, Pressable, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function LinkButton({text, screen} ) {

    const navigation = useNavigation();

    return (
        <Pressable onPress={() => navigation.navigate(screen)}>
            <Text style={styles.link}>{text}</Text>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    link: {
        fontFamily: 'Candara',
        color: 'black',
        fontSize: 18,
        marginVertical: 10
    },
})