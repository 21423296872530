import React, { useState, useEffect } from 'react';
import { View, Text, Image, StyleSheet, Pressable } from 'react-native';
import { Audio } from 'expo-av';

import api from '../api/client';
import server from '../config/server';

export default function Book({ navigation, route }) {

    const domain = server['domain'];
    const { BID } = route.params;

    const [book, setBook] = useState(null);

    const audio = React.useRef(new Audio.Sound());

    useEffect(() => {
        fetchData();
        playBook();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Book/' + BID);
        setBook(data);
    }

    const navigate = () => {
        navigation.navigate("BookText", { BID: BID });
    }

    const playBook = async () => {        
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/book/' + BID + '/0.m4a'});
        audio.current = sound;
        audio.current.playAsync();

        setTimeout(() => {
            navigate();
        }, 6000);
    }

    return (
        book &&
        <View style={styles.container}>
            <View style={styles.left}>
                <Pressable onPress={() => navigate()}>
                    <View style={styles.card}>
                        <Image style={styles.image} source={{uri: domain + '/images/book/' + BID + '.jpg'}} />
                    </View>
                </Pressable>
            </View>
            <View style={styles.right}>
                <Text style={styles.bookTitle}>{book.chinese}</Text>
                <Text style={styles.english}>{book.english}</Text>
                <View style={styles.row}>
                    <Text style={styles.text}>作者</Text>
                    <Text style={styles.author}>{book.author}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.text}>译者</Text>
                    <Text style={styles.translator}>{book.translator}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 30
    },
    card: {
        backgroundColor: 'white',
        width: 320,
        height: 320,
        margin: 20,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: '0 10px 20px rgb(0 0 0 / 0.3)',
    },
    image: {
        width: 300,
        height: 300,
        resizeMode: 'contain',
    },
    bookTitle: {
        fontFamily: 'STZhongsong',
        fontSize: 50,
        marginBottom: 30
    },
    author: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
        marginBottom: 30
    },
    english: {
        fontFamily: 'STZhongsong',
        fontSize: 40,
        marginBottom: 30
    },
    row: {
        flexDirection: 'row',
    },
    translator: {
        fontFamily: 'STZhongsong',
        fontSize: 30,
    },
    text: {
        fontFamily: 'Microsoft Black',
        fontSize: 25,
        marginRight: 30
    },
})