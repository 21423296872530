import React, { useState, useEffect, useRef } from 'react';
import { View, Button, StyleSheet } from 'react-native';
import Person from './Person';
import Sentences from './Sentences';

import api from '../api/client';

export default function SampleDialog() {

    const [newWords, setNewWords] = useState([]); //New vocabularies in this text

    const [pages, setPages] = useState([]);
    const [pageID, setPageID] = useState(1);

    const [left, setLeft] = useState(null);
    const [right, setRight] = useState(null);

    const textIDs = [62,63,64,65,100,101,102,103];
    const [index, setIndex] = useState(Math.floor(Math.random() * 8));  //0 .. 7

  //  const btnRef = useRef(null);

    // useEffect(() => {
    //      btnRef.current.click();
    // }, []);

    useEffect(() => {
        fetchText();
    }, [index]);

    useEffect(() => {
        if (pages == undefined || pages.length < 1)
            return;

        if (pageID <= pages.length)
            updatePage();
        else {
            setTimeout(() => {
                if (index >= textIDs.length)
                    setIndex(0);
                else
                    setIndex(index + 1);
                setPageID(1);
            }, 2000);
        }
    }, [pageID, pages]);

    const fetchText = async () => {
        const { data } = await api.get('/Dialog/Sample/' + textIDs[index]);
        setPages(data.item1);
        setNewWords(data.item2);
    }

    const updatePage = () => {
        let page = pages.filter(p => p.pageID == pageID)[0];
        const { cLeft, cRight } = page;

        if (cLeft.length > 0) setLeft(cLeft);
        if (cRight.length > 0) setRight(cRight);
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                {left && <Person name={left} />}
            </View>
            <View style={styles.center}>
                <Sentences textID={textIDs[index]} pageID={pageID} newWords={newWords} onFinish={() => setPageID(pageID + 1)} />
            </View>
            <View style={styles.right}>
                {right && <Person name={right} />}
            </View>
            {/* <View style={{opacity: 0}}>
                <Button onPress={() => console.log('Hidden Button Clicked')} ref={ref => btnRef.current = ref } />
            </View> */}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    left: {
        width: '15%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
    center: {
        minWidth: '68%',
        width: 'auto',
    },
    right: {
        width: 'auto',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
})