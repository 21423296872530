import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import MinusPlus from './MinusPlus';
import ProductInfo from './ProductInfo';

import api from '../api/client';
import server from '../config/server';

export default function ProductUpdate({ productID, optionID, onUpdate }) {

    const domain = server["domain"];
    const [item, setItem] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data: item } = await api.get('/Cart/Item/' + productID + '/' + optionID);
        setItem(item);
        onUpdate(item.price, 1);
    }

    const handleUpdate = async (qty) => {
        let sum = item.price * qty;
        onUpdate(sum, qty);
    }

    return (
        item &&
        <View style={styles.container}>
            <View style={styles.vImage}>
                <Image style={styles.image} source={{uri: domain + '/images/shop/'+ productID + '/1.jpg'}} />
            </View>
            <View style={{width: '50%'}}>
                <ProductInfo brand={item.brand} name={item.name} option={item.optName} price={item.price} />
            </View>
            <View style={styles.vBtn}>
                <MinusPlus id={item.pid} qty={1} onUpdate={ qty => handleUpdate(qty)} onDelete={() => {}} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 5
    },
    vImage: {
        width: '30%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    image: {
        width: '18vh',
        height: '18vh',
        resizeMode: 'contain',
    },
    vBtn: {
        width: '15%',
        alignItems: 'center'
    },
})