import React, { useState, useEffect } from 'react';
import { View, Image, Text, FlatList, StyleSheet } from 'react-native';
import PauseButton from './PauseButton';

import { Audio } from 'expo-av';
import server from '../config/server';

export default function Vocabulary({ vocab, onFinish }) {

    const { url, pinyin, chinese, english } = vocab;

    const domain = server['domain'];
    const soundUrl = domain + "/sound/vocab/" + vocab.catID + "/";
    const imageUrl = domain + "/images/vocab/" + vocab.catID + "/";

    const [words, setWords] = useState([]);

    const [paused, setPaused] = useState(false);
    const [times, setTimes] = useState(0);

    const audio = React.useRef(new Audio.Sound());

    useEffect(() => {
        setTimes(0);
        setPaused(false);
        splitWords();

        return () => {
            audio.current.unloadAsync();
        }
    }, [vocab]);

    useEffect(() => {
        if (times < 2 && !paused)
            playAudio();
        if (times >= 2)
            onFinish();
    }, [times]);

    useEffect(() => {
        if (paused)
            return;
        
        playAudio();
    }, [paused]);

    const playAudio = async () => {
        const { sound } = await Audio.Sound.createAsync({uri: soundUrl + url + '.m4a'});
        audio.current = sound;
        audio.current.playAsync();

        setTimeout(() => {
            setTimes(times + 1);
        }, 4000);
    }

    const splitWords = () => {
        let pins = pinyin.trim().split(/\s+/);
        let words = [...chinese];
        let combine = words.map((e, i) => [e, pins[i]]);
        setWords(combine);
    }

    const renderItem = ({item}) => {
        return (
            <View style={styles.card}>
                <Text style={styles.pinyin}>{item[1]}</Text>
                <Text style={styles.chinese}>{item[0]}</Text>
            </View>
        );
    }
    
    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.left}>
                    <Image style={styles.image} source={{uri: imageUrl + url + '.jpg'}} />
                </View>
                <View style={styles.right}>
                    <View style={{flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                        <FlatList
                            data={words}
                            keyExtractor={(item, index) => index}
                            renderItem={renderItem}
                            horizontal={true}
                        ></FlatList>
                    </View>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
                        <Text style={styles.english}>{english}</Text>
                    </View>
                </View>
            </View>
            <PauseButton paused={paused} onPause={() => setPaused(!paused)} onPlay={() => setPaused(!paused)} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    row: {
         flex: 1,
         flexDirection: 'row',
    },
    left: {
        flex: 2,
        backgroundColor: 'white',
        padding: 50,
    },
    right: {
        flex: 3,
        flexDirection: 'column',
        backgroundColor: '#D1F2EB',
    },
    image: {
        resizeMode: 'contain',
        width: '100%',
        height: '100%',
        pointerEvents: 'none'
    },
    card: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pinyin: {
        fontFamily: 'Calibri',
        fontSize: 60,
    },
    chinese: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 150,
        letterSpacing: 20,
    },
    english: {
        fontFamily: 'Comic Sans MS',
        fontSize: 60,
    },
})