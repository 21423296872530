import React, { useState, useEffect } from 'react';
import { View, Text, Image, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import PlanPrice from './PlanPrice';
import PlanTerm from './PlanTerm';

import api from '../api/client';
import useAuth from '../hooks/useAuth';
import server from '../config/server';

export default function PaymentPlan({ navigation }) {

    const domain = server['domain'];

    const { user } = useAuth();

    const [month, setMonth] = useState(29);
    const [month3, setMonth3] = useState(59);
    const [year, setYear] = useState(179);

    const [monthNow, setMonthNow] = useState(29);
    const [month3Now, setMonth3Now] = useState(59);
    const [yearNow, setYearNow] = useState(179);

    const [dead1, setDead1] = useState(null);
    const [dead2, setDead2] = useState(null);
    const [dead3, setDead3] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Member/Prices/');

        setMonth(data.item1);
        setMonth3(data.item2);
        setYear(data.item3);
        setMonthNow(data.item4);
        setMonth3Now(data.item5);
        setYearNow(data.item6);

        const { data: data2 } = await api.get('/Member/Deadline/');
        setDead1(data2.item1);
        setDead2(data2.item2);
        setDead3(data2.item3);
    }

    const handleSelect = (type) => {
        if (user == undefined)
            return navigation.navigate("Login3", { prevScreen: 'PaymentPlan' });

        if (type === 3)
            return navigation.navigate("Deduction", { amount: yearNow });

        const amount = type === 1? monthNow : month3Now;
        navigation.navigate("PayPal", { amount: amount, type: type });
    }

    return (
        <View style={styles.container}>
            <View style={styles.box}>
                {/* <Text style={styles.title}>SUMMER DEAL</Text> */}
                <Text style={styles.title}>Choose Your Plan</Text>
            </View>
            <View style={styles.box2}>
                {/* <Text style={styles.subTitle}>$80 Off Annual Plan - Ends July 31</Text> */}
            </View>
            <View style={styles.row}>
                <Pressable style={[styles.card, styles.left]} onPress={() => handleSelect(1)}>
                    <MaterialCommunityIcons name='airballoon' color='#FE9600' size={80} />
                    <Text style={styles.type}>1 month</Text>
                    <PlanPrice price={month} priceNow={monthNow} days={30} deadline={dead1} />
                    <View style={styles.terms}>
                        <PlanTerm text='30 days VIP' icon='calendar' color='black' />
                        <PlanTerm text='Access all content' icon='cat' color='black' />
                    </View>
                    {/* <Image style={styles.buynow} source={{ uri: domain + '/images/common/buy-now.png'}} /> */}
                </Pressable>
            
                <Pressable style={[styles.card, styles.center]} onPress={() => handleSelect(2)}>
                    <MaterialCommunityIcons name='airplane' color='#A020F0' size={80} />
                    <Text style={styles.type}>3 months</Text>
                    <PlanPrice price={month3} priceNow={month3Now} days={90} deadline={dead2} />
                    <View style={styles.terms}>
                        <PlanTerm text='90 days VIP' icon='calendar' color='black' />
                        <PlanTerm text='Access all content' icon='cat' color='black' />
                    </View>
                </Pressable>

                <Pressable style={[styles.card, styles.right]} onPress={() => handleSelect(3)}>
                    <MaterialCommunityIcons name='rocket-launch' color='white' size={80} />
                    <Text style={styles.type}>12 months</Text>
                    <PlanPrice price={year} priceNow={yearNow} days={365} deadline={dead3} />
                    <View style={styles.terms}>
                        <PlanTerm text='365 days VIP' icon='calendar' color='white' />
                        <PlanTerm text='Access all content' icon='cat' color='white' />
                        <PlanTerm text='Coupon applicable' icon='gift' color='white' />
                    </View>
                </Pressable>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#141e28',
        alignItems: 'center',
    },
    box: {
        paddingHorizontal: 50,
        paddingVertical: 5,
        backgroundColor: '#ea3fd3',
        transform: 'rotate(-2deg) skew(-2deg)',
        marginVertical: 15,
        borderRadius: 10
    },
    box2: {
        marginTop: 15,
        marginBottom: 20
    },
    title: {
        fontFamily: 'Arial Black',
        fontSize: 50,
        fontWeight: 'bold',
        color: 'white'
    },
    subTitle: {
        fontFamily: 'Arial',
        fontSize: 28,
        fontWeight: 'bold',
        color: 'white'
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    card: {
        position: 'relative',
        width: '50%',
        minHeight: '90%',
        height: 'auto',
        borderRadius: 20,
        alignItems: 'center',
        padding: 20,
        boxShadow: '0 5px 15px rgb(0 0 0 / 0.3)',
    },
    left: {
        backgroundColor: '#00BFFF',
    },
    center: {
        backgroundColor: '#4CBB17',
        marginHorizontal: 40
    },
    right: {
        backgroundColor: '#A020F0',
    },
    type: {
        color: 'white',
        fontSize: 25,
    },
    buynow: {
        width: 205,
        height: 78
    },
    terms: {
        height: 'auto',
        position: 'absolute',
        bottom: 30,
        left: 30
    }
})