import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Audio } from 'expo-av';

import server from '../config/server';

export default function HSKText({ navigation, route }) {

    const { text } = route.params;
    const domain = server["domain"];

    const audio = React.useRef(new Audio.Sound());

    useEffect(() => {
        playText();
        return () => {
            audio.current.unloadAsync();
        }
    }, []);

    const playText = async () => {      
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/text/HSK' + text.hsk + '/' + text.tid + '/' + '0.mp3'});  
        audio.current = sound;
        audio.current.playAsync();

        setTimeout(() => {
            if (text.isDialog)
                return navigation.navigate("Dialog", { textID: text.tid });
            else
                return navigation.navigate("Paragraph", { textID: text.tid });
        }, text.duration * 1000 + 1000);
    }

    return (
        <View style={styles.container}>
            <View style={{flex: 1, justifyContent: 'flex-end'}}>
                <Text style={styles.title}>课文</Text>
            </View>
            <View style={{flex: 1, justifyContent: 'center'}}>
            {/* <Text style={styles.textID}>{text.textID}</Text> */}
            </View>
            <View style={{flex: 3, justifyContent: 'flex-start', alignItems: 'center'}}>
                <Text style={styles.chinese}>{text.chinese}</Text>
                <Text style={styles.english}>{text.english}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#EBF5FB',
    },
    title: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 60,
        letterSpacing: 5,
        marginTop: '15%'
    },
    textID: {
        fontSize: 60,
    },
    chinese: {
        fontFamily: 'STXinwei',
        fontSize: 80,
        letterSpacing: 10,
        marginTop: '5%'
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 60
    }
})