import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { LessonContext } from './Context';

import Person from './Person';
import Sentences from './Sentences';

import api from '../api/client';

export default function Dialog({ navigation, route }) {
    const { textID } = route.params;

    const lesson = React.useContext(LessonContext);

    const [newWords, setNewWords] = useState([]); //New vocabularies in this text

    const [pages, setPages] = useState([]);
    const [pageID, setPageID] = useState(1);

    const [left, setLeft] = useState(null);
    const [right, setRight] = useState(null);

    useEffect(() => {
      //  let controller = new AbortController();
        fetchData();
     //   return () => controller?.abort();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Dialog/Text/' + textID);
        setPages(data.item1);
        setNewWords(data.item2);
    }

    const updatePage = () => {
        let page = pages.filter(p => p.pageID == pageID)[0];
        const { cLeft, cRight } = page;

        if (cLeft.length > 0) setLeft(cLeft);
        if (cRight.length > 0) setRight(cRight);
    }

    useEffect(() => {
        if (pages.length < 1) return;

        if (pageID <= pages.length) {
            updatePage();
        }
        else
            setTimeout(() => {
                navigation.navigate("HSKTexts", { lessonID: lesson.lid });
            }, 2000);
    }, [pageID, pages]);

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                {left && <Person name={left} />}
            </View>
            <View style={styles.center}>
                <Sentences textID={textID} pageID={pageID} newWords={newWords} onFinish={() => setPageID(pageID + 1)} />
            </View>
            <View style={styles.right}>
                {right && <Person name={right} />}
            </View>   
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    left: {
        width: '15%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
    center: {
        minWidth: '70%',
        width: 'auto',
    },
    right: {
        width: 'auto',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
})