import React, { useState } from 'react';
import { View, Text, TouchableHighlight, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function MinusPlus({ id, qty, onUpdate, onDelete }) {

    const [quantity, setQuantity] = useState(qty);
        
    const minus = () => {
        if (quantity == 1)
            onDelete(id);
        else {
            setQuantity(quantity - 1);
            onUpdate(quantity - 1);
        }
    }

    const plus = () => {
        setQuantity(quantity + 1);
        onUpdate(quantity + 1);
    }

    return (
        <View style={styles.container}>
            <TouchableHighlight onPress={() => minus()}>
                <MaterialCommunityIcons name={'minus'} color='black' size={25} />
            </TouchableHighlight>
            <View style={{padding: 5}}>
                <Text style={styles.qty}>{quantity}</Text>
            </View>
            <TouchableHighlight onPress={() => plus()}>
                <MaterialCommunityIcons name={'plus'} color='black' size={25} />
            </TouchableHighlight>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    qty: {
        fontSize: 25
    }
})