import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { Octicons } from '@expo/vector-icons';

export default function UpDown({ closed, onPress }) {
    return (
        <Pressable onPress={() => onPress(!closed)}>
            {closed? 
            <View style={styles.container}>
                <Text style={{marginRight: 20}}>View All</Text>
                <Octicons name='triangle-down' color='black' size={30} /> 
            </View>:
            <View style={styles.container}>
                <Text style={{marginRight: 20}}>Close</Text>
                <Octicons name='triangle-up' color='black' size={30} />
            </View>}     
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: 'center',
    },
})