import React, { useState, useEffect } from 'react';
import { View, Text, Image, FlatList, StyleSheet } from 'react-native';
import DeleteCancel from './DeleteCancel';
import MinusPlus from './MinusPlus';
import MyButton from './form/MyButton';
import Price from './Price';
import CheckBox from './CheckBox';
import ProductInfo from './ProductInfo';

import api from '../api/client';
import useAuth from '../hooks/useAuth';
import server from '../config/server';

export default function ShoppingCart({ navigation }) {

    const { user } = useAuth();
    const domain = server['domain'];

    const [data, setData] = useState([]);
    const [sum, setSum] = useState(1000);
    const [deleteID, setDeleteID] = useState(0);
    const [showButtons, setShowButtons] = useState(false);
    const [all, setAll] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Cart/' + user.personID);
        setData(data);
        setSum(getSum(data));
    }

    const initAll = (data) => {
        if (data.length > 0) {
            let a = data.reduce((a, d) => {return a && d.checked}, true);
            setAll(a);
        }
    }

    const handleUpdate = async (cid, qty, checked) => {

        await api.put('/Cart/' + cid + '/' + qty);

        let index = data.findIndex((obj => obj.cid === cid));
        data[index].quantity = qty;

       // user.cart = data;

        if (checked)
            setSum(getSum(data));
    }

    const getSum = (data) => {
        let list = data.filter(d => d.checked === true);
        let sum = list.reduce((acc, obj) => {
            return acc + obj.price * obj.quantity;
        }, 0);

        return sum.toFixed(2);
    }

    const showModal = (cid) => {
        setDeleteID(cid);
        setShowButtons(true);
    }

    const handleDelete = async () => {
        await api.put('/Cart/' + deleteID + '/' + 0);
        fetchData();

        setShowButtons(false);
    }

    const selectItem = async (cid, checked) => {
        await api.put('/Cart/' + cid);
        fetchData();
    }

    const selectAll = async () => {
        await api.put('/Cart/All/' + user.personID + '/' + !all);
        setAll(!all);
        fetchData();
    }

    const renderItem = ({item}) => {
        const { cid, pid, brand, name, optName, price, quantity, checked } = item;
        return (
            <View style={styles.card}>
                <View style={styles.vBox}>
                    <CheckBox value={checked} onValueChange={() => selectItem(cid, !checked)} />
                </View>
                <View style={styles.vImage}>
                    <Image style={styles.image} source={{uri: domain + '/images/shop/'+ pid + '/1.jpg'}} />
                </View>
                <View style={{width: '50%'}}>
                    <ProductInfo brand={brand} name={name} option={optName} price={price} />
                </View>
                <View style={styles.vBtn}>
                    <MinusPlus id={cid} qty={quantity} onUpdate={ qty => handleUpdate(cid, qty, checked)} onDelete={ cid => showModal(cid) } />
                </View>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <View style={{flex: 9, marginVertical: 10, width: '70%'}}>
                <FlatList
                    data={data}
                    numColumns={1}
                    keyExtractor={item => item.cid}
                    renderItem={renderItem}
                ></FlatList>
            </View>
            <View style={{flex: 1, flexDirection: 'row', width: '70%'}}>
                <View style={{width: '30%', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <CheckBox value={all} onValueChange={() => selectAll()} />
                    <Text style={{marginLeft: 10, fontSize: 18, fontFamily:'Candara'}}>Select All </Text>
                </View>
                <View style={{width: '40%', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Text style={{marginRight: 10, fontSize: 18, fontFamily: 'Candara'}}>Total:</Text>
                    <Price price={sum} />
                </View>
                <View style={{width: '30%', justifyContent: 'center', alignItems: 'flex-end'}}>
                    {sum > 0 &&
                        <View style={{width: '100%'}}>
                            <MyButton text="Checkout" color="black" onPress={() => navigation.navigate("Checkout")} />
                        </View>
                }
                </View>
            </View>
            <DeleteCancel visible={showButtons} onDelete={() => handleDelete()} onCancel={() => setShowButtons(false)}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray'
    },
    vBox: {
        width: '10%',
        justifyContent: 'center'
    },
    vImage: {
        width: '25%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 5,
        marginRight: 10
    },
    image: {
        width: '18vh',
        height: '18vh',
        resizeMode: 'contain',
    },
    vBtn: {
        width: '10%',
        alignItems: 'flex-end'
    },
    button: {
        width: '100%',
        height: 70,
        justifyContent: 'center',
        alignItems: 'center'
    },
})