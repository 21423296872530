import React from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import color from '../config/color';

export default function AdvancedMenu({ navigation }) {
    const { hsk1, hsk2, hsk3, hsk4, hsk5, hsk6 } = color;

    const colors = [hsk4, hsk2, hsk3, hsk5, hsk6];
    const cats = ['文化', '俗语', '成语', '谚语'];

    const handlePress = (index) => {
        switch(index) {
            case 0:
                navigation.navigate("CultureNavigator");
                break;
            case 1:
                navigation.navigate("SayingNavigator");
                break;
            case 2:
                navigation.navigate("Idiom");
                break;
            case 3:
                navigation.navigate("Proverb");
                break;
            // case 4:
            //     navigation.navigate("TopicNavigator");
            //     break;
        }
    }

    const renderItem = ({ item, index }) => (
        <TouchableOpacity onPress={() => handlePress(index)}>
            <View style={[styles.card, {backgroundColor: colors[index]}]}>
                <Text style={styles.text}>{item}</Text>
            </View>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>
            <View>
                <FlatList
                    data={cats}
                    keyExtractor={item => item}
                    renderItem={renderItem}
                    numColumns={4}
                ></FlatList>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    title: {
        fontSize: 20,
    },
    card: {
        width: '15vw',
        height: '25vw',
        padding: 30,
        margin: 30,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 5px 15px rgb(0 0 0 / 0.5)',
        textOrientation: 'mixed',
        writingMode: 'vertical-rl'
    },
    text: {
        fontFamily: 'STZhongsong',
        fontSize: 60,
    },
})