import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';

import api from '../api/client';

export default function Idiom({ cID }) {
    
    const [data, setData] = useState(null);
    const [characters, setCharacters] = useState([]);

    useEffect(() => {
        fetchData();
    }, [cID]);

    const fetchData = async() => {
        const { data } = await api.get('/Advanced/Idiom/' + cID);
        setData(data);
        splitWords(data.pinyin, data.chinese);
    }

    const splitWords = (pinyin, chinese) => {
        let words = [];
        let pins = [];

        if (chinese.length == 1) {
            pins = [pinyin];
            words = [chinese];
        } else {
            pins = pinyin.split(/\s+/);
            words = chinese.split(/\s+/);

            if (pins.length != words.length)
                words = [...chinese];
        }
 
        let combine = words.map((e, i) => [e, pins[i]]);
        setCharacters(combine);
    }

    const renderItem = ({item}) => {
        return (
            <View style={styles.word}>
                <Text style={styles.pinyin}>{item[1]}</Text>
                <Text style={styles.chinese}>{item[0]}</Text>
            </View>
        );
    }

    return (
        data &&
        <View style={styles.container}>
            <View style={styles.row1}>
                <FlatList
                    data={characters}
                    keyExtractor={(item, index) => index}
                    renderItem={renderItem}
                    horizontal={true}
                ></FlatList>
            </View>
            <View style={styles.row2}>
                <Text style={styles.english}>{data.english}</Text>
            </View>
            <View style={styles.row3}>
                <Text style={styles.example}>{data.example}</Text>
                <Text style={styles.expEnglish}>{data.expEnglish}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
    },
    row1: {
        height: '40%',
        paddingVertical: 30,
        alignItems: 'center',
        padding: 20
    },
    row2: {
        height: '15%',
        paddingVertical: 30,
        paddingLeft: '5vw',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    row3: {
        height: '45%',
        paddingVertical: 50,
        paddingLeft: '5vw',
        alignItems: 'center',
    },
    word: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    pinyin: {
        fontFamily: 'Calibri',
        fontSize: 40,
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 80,
        letterSpacing: 10,
    },
    english: {
        fontFamily: 'STZhongsong',
        fontSize: 30, 
    },
    expEnglish: {
        fontFamily: 'STZhongsong',
        fontSize: 26
    },
    example: {
        fontFamily: 'STZhongsong',
        fontSize: 40,
        marginBottom: 15
    },
})