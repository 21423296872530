import React, { useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, Message, ErrorMessage } from './form';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import IconView from './IconView';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

const schema = Yup.object().shape({
    password: Yup.string().required().min(6).max(16).label("Password"),
    newPass: Yup.string().required().min(6).max(16).label("New Password")
});

export default function PassChange() {
    
    const { user } = useAuth();
    const domain = server["domain"];
    
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const updatePass = async (password, newPass) => {
        setError(null);
        setMessage(null);

        if (password == newPass) {
            setError('New password cannot be same as password.');
            return;
        }

        const { data } = await api.put("/User/UpdatePass/" + user.personID + "/" + password + "/" + newPass);
        if (data < 0)
            setError('Password is incorrect.');
        else
            setMessage('Password updated successfully.');
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.envelope} source={{uri: domain + '/images/common/pass-reset.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={styles.form}>
                    <IconView color='#febb22'>
                        <MaterialCommunityIcons name="onepassword" size={100} color="white" />
                    </IconView>
                    <Form
                        initialValues={{ password: '', newPass: '' }}
                        onSubmit={({password, newPass}) => updatePass(password, newPass) }
                        validationSchema={schema}
                    >
                        <View style={{marginBottom: 10}}>
                            {error && <ErrorMessage text={error} />}
                            {message && <Message text={message} />}
                        </View>
                        <FormField 
                            name="password"
                            icon="lock"
                            placeholder="Password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            textContentType="password"
                            secureTextEntry={true}
                        />
                        <FormField 
                            name="newPass"
                            icon="lock"
                            placeholder="New Password"
                            autoCapitalize="none"
                            autoCorrect={false}
                            textContentType="password"
                            secureTextEntry={true}
                        />
                        <View style={{width: '100%'}}>
                            <SubmitButton text="Update" />
                        </View>
                    </Form>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    form: {
        width: '75%',
        height: 'auto',
        padding: 50,
        borderRadius: 30,
        alignItems: 'center',
        backgroundColor: '#e9f3fd',
    },
    envelope: {
        width: 350,
        height: 350,
    },
    buttonV: {
        width: '100%',
        height: 100,
    }
})