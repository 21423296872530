import React, { useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, Message, ErrorMessage } from './form';
import IconView from './IconView';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';

const schema = Yup.object().shape({
    firstName: Yup.string().required().min(2).max(20).label("First Name"),
    lastName: Yup.string().required().min(2).max(20).label("Last Name"),
    email: Yup.string().required().email().label("Email")
})

export default function Register() {

    const domain = server["domain"];

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const register = async (firstName, lastName, email) => {
        setError(false);
        setSuccess(false);

        const { data: personID } = await api.get("/User/" + firstName + "/" + lastName + "/" + email);

        if (personID < 0)
            setError(true);
        else {
            setSuccess(true);
            await api.put("/User/Init/" + personID + "/" + firstName + "/" + email);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.image} source={{uri: domain + '/images/common/dinosaur4.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={styles.form}>
                    <IconView>
                        <Image style={styles.userIcon} source={{uri: domain + '/images/common/registration.png'}} />
                    </IconView>
                    <Form
                        initialValues={{ firstName: '', lastName: '', email: '' }}
                        onSubmit={({firstName, lastName, email}) => register(firstName, lastName, email) }
                        validationSchema={schema}
                    >
                        <View style={{marginBottom: 10}}>
                        {error && <ErrorMessage text="This email account already exist. Please try another one." />}
                        {success && <Message text="Congratulations！Your account registered successfully. Please go to your email and find password." />}
                        </View>
                        <FormField 
                            name="firstName"
                            icon="panda"
                            placeholder="First Name"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="givenName"
                        />
                        <FormField 
                            name="lastName"
                            icon="panda"
                            placeholder="Last Name"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            textContentType="familyName"
                        />
                        <FormField 
                            name="email"
                            icon="email"
                            placeholder="Email"
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="email-address"
                            textContentType="emailAddress"
                        />
                        <View style={{width: '100%', marginBottom: 20}}>
                            <SubmitButton text="Sign Up" />
                        </View>
                    </Form>
                </View>  
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    form: {
        width: '75%',
        height: 'auto',
        padding: 50,
        borderRadius: 30,
        alignItems: 'center',
        backgroundColor: '#D6EAF8'
    },
    userIcon: {
        width: 146,
        height: 146,  
    },
    image: {
        width: 420,
        height: 498,
    },
})
