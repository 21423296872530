import React, { useState, useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import api from '../api/client';
import server from '../config/server';

export default function Person({name}) {
    const [width, setWidth] = useState(220);
    const [height, setHeight] = useState(680);

    const winHeight = Dimensions.get('window').height;
    const domain = server["domain"];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Dialog/Size/' + name);
        let width = data.item1;
        let height = data.item2;

        height = Math.floor(winHeight * 0.75);
        width = Math.floor(width * (height / data.item2));

        setWidth(width);
        setHeight(height);
    }

    return (
        <Image style={[styles.image, {width: width, height: height}]} source={{uri: domain + '/images/person/' + name + '.jpg'}} />
    );
}

const styles = StyleSheet.create({
    image: {
        zIndex: -1,
        resizeMode: 'contain'
    }
})