import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';

export default function LinkText({text, visible, onPress}) {
    const style = visible? {visibility: 'visible'} : {visibility: 'hidden'};

    return (
        <Pressable onPress={onPress}>
            <View style={[styles.card, style]}>
                <Text style={styles.title}>{text}</Text>
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    card: {
        width: '14vh',
        height: '14vh',
        backgroundColor: 'black',
        borderWidth: 5,
        borderColor: '#FE9600',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },
    title: {
        fontSize: 30,
        color: '#FE9600',
    },
})