import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import ChatForm from '../components/ChatForm';

const Stack = createNativeStackNavigator();

const ChatNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>
            {!user && <Stack.Screen name="LoginChat" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            <Stack.Screen name="ChatForm" component={ChatForm} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default ChatNavigator;