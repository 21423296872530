import React, { useState, useEffect } from 'react';
import Vocabulary from './Vocabulary';

import api from '../api/client';

export default function VocabManager({ navigation, route }) {

    const { catID } = route.params;
    
    const [data, setData] = useState(null);
    const [index, setIndex] = useState(0);
    const [vocab, setVocab] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/Cat/' + catID);
        setData(data);
        setVocab(data[0]);
    }

    useEffect(() => {
        if (data == null) return;

        if (index >= data.length)
            return navigation.navigate("VocabularyMenu");

        setVocab(data[index]);
    }, [index]);
    
    return (
        vocab && <Vocabulary vocab={vocab} onFinish={() => setIndex(index + 1)} />
    );
}