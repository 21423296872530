import React from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

export default function CheckBox({ value, onValueChange }) {
    return (
        <Pressable onPress={onValueChange}>
            <View style={styles.box}>
                {value && <AntDesign name='check' color='black' size={20} />}
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    box: {
        width: 25,
        height: 25,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        borderWidth: 2,
        borderColor: 'black'
    },
})