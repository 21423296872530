import React, { useState, useEffect } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import ProductRow from './ProductRow';
import ProductCard from './ProductCard';
import UpDown from './UpDown';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function CheckoutProducts() {

    const { user } = useAuth();

    const [data, setData] = useState([]);
    const [closed, setClosed] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Cart/Checkout/' + user.personID);
        setData(data);
    }

    // const getSum = (data) => {
    //     let sum = data.reduce((acc, obj) => {
    //         return acc + obj.price * obj.quantity;
    //     }, 0);

    //     return sum.toFixed(2);
    // }

    // const getCount = (data) => {
    //     let count = data.reduce((acc, obj) => {
    //         return acc + obj.quantity;
    //     }, 0);

    //     return count;
    // }

    const renderFirst = () => {
        if (data[0] == null) return (<></>);
        return (
            <View style={styles.container}>
                <ProductRow product={data[0]} />
                {data.length > 1 && 
                 <View style={{marginBottom: 8}}>
                    <UpDown closed={closed} onPress={c => setClosed(c)} />
                </View>}
            </View>
        )
    }

    const renderCard = ({item}) => {
        return (<ProductCard product={item} />)
    }

    const renderMulti = () => {
        return (
            <View style={styles.container}>
                <View style={{width: '100%', padding: 10, alignItems: 'center'}}>
                    <FlatList
                        horizontal={true}
                        data={data}
                        keyExtractor={(item, index) => index}
                        renderItem={renderCard}
                    ></FlatList>
                </View>
                <View style={{marginBottom: 8}}>
                    <UpDown closed={closed} onPress={c => setClosed(c)} />
                </View>
            </View>
        )
    }

    return (
        closed? renderFirst() : renderMulti()
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
    },
})