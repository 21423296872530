import React, { useState, useEffect } from 'react';
import { View, Text, Image, FlatList, Modal, Button, Pressable, StyleSheet } from 'react-native';
import { Audio } from 'expo-av';
import server from '../config/server';

import LinkImage from './LinkImage';
import LinkText from './LinkText';
import api from '../api/client';

const cats = [1,2,3,5,6,7,8,9,10,12];
const domain = server["domain"];

export default function LinkGame() {

    const [data, setData] = useState([]);
    const [lastID, setLastID] = useState(0);
    const [lastKey, setLastKey] = useState('');
    const [count, setCount] = useState(0);
    const [catID, setCatID] = useState(1);
    const [sound, setSound] = useState(null);

    const [visible, setVisible] = useState(false);

    const updateData = async () => {
        let index = Math.floor(Math.random() * 8);
        let categoryID = cats[index];
        setCatID(categoryID);

        const { data } = await api.get('/Vocabulary/Link/' + categoryID);

        let url = [];
        let chinese = [];
        let arr = [];
        let len = data.length;

        for (let i = 0; i < len; i++) {
            let c = Object.assign({}, data[i]);
            delete c.chinese;
            c.key = c.url;
            c.show = true;
            url[i] = c;

            let d = Object.assign({}, data[i]);
            delete d.url;
            d.key = '' + d.vid;
            d.show = true;
            chinese[i] = d;
        }

        let ranIDs = shuffle(len);

        for (let i = 0; i < len; i++) {
            arr[i*2] =  url[i];
            arr[i*2+1] = chinese[ranIDs[i]-1];
        }

        setData(arr);
        setVisible(false);

        const { sound } = await Audio.Sound.createAsync(require('../assets/sound/select.mp3'));
        setSound(sound);
    }

    const shuffle = (len) => {
        let ran1, ran2;
        let arr = [];

        for (let i = 0; i < len; i++) {
            arr[len-i-1] = i+1;
        }

        for (let i = 0; i < len/3; i++) {
            ran1 = Math.floor(Math.random() * len);
            ran2 = Math.floor(Math.random() * len);
            [arr[ran1], arr[ran2]] = [arr[ran2], arr[ran1]];
        }
      
        return arr;
    }

    useEffect(() => {
        updateData();
    }, []);

    const handlePress = async (item) => {
        if (item.vid !== lastID || item.key === lastKey) {
            setLastID(item.vid);
            setLastKey(item.key);
            return;
        }

        await sound.playAsync();

        let da = data.filter(item => item.vid === lastID? item.show = false: true);
        setData(da);

        let c = count + 1;
        if (c === 27) {
            setVisible(true);
        } else {
            setCount(c);
        }

        setLastID(0);
    }

    const renderItem = ({ item }) => (
        item.url? 
            <LinkImage url={item.url} cat={catID} visible={item.show} onPress={() => handlePress(item) } /> :
            <LinkText text={item.chinese} visible={item.show} onPress={() => handlePress(item) } />
    );

    return (
        <View style={styles.container}>
            <View>
            <FlatList
                data={data}
                keyExtractor={item => item.key}
                renderItem={renderItem}
                numColumns={9}
            ></FlatList>
            <Modal
                animationType="slide"
                transparent={true}
                visible={visible}
                onRequestClose={() => setVisible(!visible)}
            >
                <View style={styles.center}>
                    <Pressable style={styles.modal} onPress={() => updateData()}>
                        <View style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                            <Text style={styles.hero}>Super Dino!</Text>
                            <View style={{width: 280, height: 50}}>
                                <Button title="Play Again" color="limegreen" onPress={() => updateData()} />
                            </View>
                        </View>
                        <View style={{alignItems: 'flex-end', justifyContent: 'center'}}>
                            <Image style={styles.image} source={{uri: domain + '/images/common/super-dino.png'}} />
                        </View>
                    </Pressable>
                </View>
            </Modal>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF5FB',
    },
    title: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 25,
        color: 'white',
        marginTop: 20,
        position: 'absolute'
    },
    center: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modal: {
        width: '40vw',
        height: '35vh',
        padding: 50,
        backgroundColor: 'black',
        borderRadius: 10,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
        top: -100
    },
    image: {
        width: 330,
        height: 360,
        top: -100,
        right: -150
    },
    hero: {
        fontFamily: 'Comic Sans MS',
        fontSize: 50,
        fontWeight: 'bold',
        color: 'limegreen',
        marginBottom: '5vh'
    },
})