import React from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';

export default function PauseButton ({ paused, onPause, onPlay }) {
    return (
        <View style={styles.container}>
            {paused?
            <View style={{flexDirection: 'row'}}>
                <Pressable onPress={onPlay} style={styles.block}>
                    <MaterialCommunityIcons name='play' color='black' size={80} />
                </Pressable>
            </View>:
            <View style={{flexDirection: 'row'}}>
                <Pressable onPress={onPause} style={styles.block}>
                    <AntDesign name='pausecircleo' color='black' size={80} />
                </Pressable>
            </View>}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#40E0D0',
        position: 'absolute',
        bottom: 10,
        zIndex: 10,
        alignSelf: 'center',
        padding: 10,
        borderRadius: 10,
        boxShadow: '0 10px 10px rgb(0 0 0 / 0.2)',
    },
    block: {
        width: 100,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: 'black'
    }
})