import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function PlanTerm({ text, icon, color }) {
    return (
        <View style={styles.container}>
            <View style={styles.iconV}>
                <MaterialCommunityIcons name={icon} color={color} size={30} />
            </View>
            <View style={styles.textV}>
                <Text style={[styles.text,{color:color}]}>{text}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingVertical: 2,
        alignSelf: 'flex-start',
        marginLeft: 20
    },
    iconV: {
        width: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginRight: 10
    },
    textV: {
        width: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    text: {
        fontSize: 18,
        marginLeft: 10
    }
})