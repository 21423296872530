import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AdvancedMenu from '../components/AdvancedMenu';
import CultureNavigator from './CultureNavigator';
import SayingNavigator from './SayingNavigator';
import IdiomManager from '../components/IdiomManager';
import ProverbManager from '../components/ProverbManager';
import TopicNavigator from './TopicNavigator';

const Stack = createNativeStackNavigator();

const AdvancedNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="AdvancedMenu" component={ AdvancedMenu } options={{ headerShown: false }} />
            <Stack.Screen name="CultureNavigator" component={ CultureNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="SayingNavigator" component={ SayingNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="TopicNavigator" component={ TopicNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="Idiom" component={ IdiomManager } options={{ headerShown: false }} />
            <Stack.Screen name="Proverb" component={ ProverbManager } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default AdvancedNavigator;