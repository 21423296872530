import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import api from '../api/client';
import color from '../config/color';

export default function Cixing({ onSelect }) {

    const [data, setData] = useState([]);
    const [cID, setCID] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Vocabulary/Cixing');
        setData(data);
    }

    const renderItem = ({item}) => {
        const { cid, english } = item;
        const c = color['' + english];
        return (
            <TouchableOpacity onPress={() => { onSelect(cid); setCID(cid) }}>
                <View style={{flexDirection: 'column', alignItems: 'center'}}>
                    <View style={[styles.box, { backgroundColor: c }]}>
                        <Text style={[styles.english, cid == 0 && {color: 'white'}]}>{ english }</Text>
                    </View>
                    {cID == cid && <Ionicons name='caret-down-outline' size={40} color={c} />}
                </View>
            </TouchableOpacity>
        );
    }

    return (
        <View style={styles.container}>
            <FlatList
                data={data}
                keyExtractor={item => item.cid}
                renderItem={renderItem}
                numColumns={13}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {   
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box: {
        minWidth: 80,
        width: 'auto',
        height: 50,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 16
    }
})