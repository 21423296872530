import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import server from '../config/server';

export default function FitableImage({ url, width, height }) {
    const [imageSize, setImageSize] = useState({});

    const domain = server['domain'];
    const imageUrl = domain + "/images/text/" + url + ".jpg";

    useEffect(() => {
        setSize();
    }, []);

    const setSize = () => {
        let w = width, h = height;

        if (width > 400) {
            w = 400;
            h = Math.round((400 / width) * height);
        }

        if (h > 550) {
            h = 550;
            w = Math.round((550 / height) * width);
        }

        setImageSize({width: w, height: h});
    }

    return (
        <View style={styles.container}>
            <Image style={[styles.image, imageSize]} source={{uri: imageUrl}}></Image>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        padding: 10,
        marginRight: 30,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    image: {
        resizeMode: 'contain',
    },
})