import React, { useState, useEffect } from 'react';
import { View, Image, Text, Modal, StyleSheet } from 'react-native';
import * as FileSystem from 'expo-file-system';

import Coupon from './Coupon';
import MyButton from './form/MyButton';

import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function Affiliate({ navigation }) {
    
    const { user } = useAuth();
    const domain = server['domain'];

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (showModal)
            setTimeout(() => {
                setShowModal(false);
                }, 3000);
    }, [showModal]);

    // const FacebookApi = "https://www.facebook.com/sharer/sharer.php?u=";
    // const TwitterApi = "https://twitter.com/intent/tweet?text=";
    // const userUrl = "http://coupon.mandarinnu.com/?code=GHDJ90";
    // const userUrl2 = "https://github.com/knoldus/angular-facebook-twitter.git";
    // const userUrl3 = "https://www.google.com";

    const shareOnFacebook = () => {
        const navUrl = FacebookApi + userUrl;
        window.open(navUrl , '_blank');
    }

    function shareOnTwitter() {
        const navUrl = TwitterApi + userUrl2;
        window.open(navUrl, '_blank');
      }

    const handlePress = async() => {
        if (user == undefined)
            return navigation.navigate("Login1", { prevScreen: 'Affiliate'});

        if (!user.isMember)
            return navigation.navigate("Plan3");

       // postFacebook();
        shareOnFacebook();
    }
     
    // const postFacebook = async () => {
    //     let parameters = [];

    //     const { data: url } = await api.get('/Setting/5');
    //     let shareURL = url + "?code=" + user.coupon;

    //     parameters.push('u=' + encodeURI(shareURL));

    //     const postContent = 'The best Chinese language course on the Internet. Use this coupon to save 20 USD.';
        
    //     parameters.push('quote=' + encodeURI(postContent));

    //     const full_url = 'https://www.facebook.com/sharer/sharer.php?' + parameters.join('&');
    
    //     Linking.openURL(full_url);
    // }

    const handleSend = async () => {
        if (user == undefined)
            return navigation.navigate("Login1", { prevScreen: 'Affiliate'});

        if (!user.isMember)
            return navigation.navigate("Plan3");

        const { firstName, email, coupon } = user;
        await api.put("/Coupon/Send/" + email + "/" + firstName + "/" + coupon);

        setShowModal(true);
    }

    const expoDownload = async () => {
        const remoteUrl = server + "/images/user/" + user.coupon + ".jpg";
        await FileSystem.downloadAsync(remoteUrl, FileSystem.documentDirectory + user.coupon + '.jpg');
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Text style={styles.title}>Share MandarinNu with a friend and you both get $20!</Text>
                <Image style={styles.image} source={{uri: domain + '/images/common/tag.jpg'}} />
            </View>
            <View style={styles.right}>
                <View style={styles.r1}>
                    <Text style={styles.title2}>
                    Earn while you network. Refer a friend to MandarinNu and receive $20 for each successful referral.
                    </Text>
                </View>
                <View style={styles.r2}>
                    <Text style={styles.terms}>When people use your unique affiliate coupon code paying membership,</Text>
                    <Text style={styles.dollar}>you will get $20 credit.</Text>
                    <Text style={styles.terms}>Your affiliate coupon code can be used unlimited times as long as you are our VIP.</Text>
                    <Text style={styles.terms}>When your membership is expired, your affiliate coupon code is expired.</Text>
                    <Text style={styles.terms}>You cannot use your own affiliate coupon paying membership.</Text>
                    {/* <Text style={styles.terms}>You can use earned credit to purchasing goods.</Text> */}
                    <Text style={styles.terms}>You can take earned credit out when it exceeds $50.</Text>
                </View>
                <View style={styles.r3}>
                    <Coupon user={user} />
                </View>

                <MyButton text='Send Coupon' color='#007AFF' width='90%' onPress={() => handleSend()} />

                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={showModal}
                    onRequestClose={() => setVisible(!showModal)}
                >
                    <View style={styles.modal}>
                        <View style={{alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                            <Text style={{fontSize: 20, fontFamily: 'Candara'}}>Coupon sent to  </Text>
                            {user && <Text style={{fontSize: 20, fontFamily: 'Candara'}}>{user.email}</Text>}
                        </View>
                    </View>
                </Modal>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '40%',
        padding: 30,
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightColor: 'black',
        borderRightWidth: 3,
        borderStyle: 'dotted',
        position: 'relative'
    },
    image: {
        resizeMode: 'contain',
        width: 375,
        height: 315,
        position: 'absolute',
        bottom: '10vh'
    },
    right: {
        width: '60%',
    },
    title: {
        fontFamily: 'SegoeUIBlack',
        fontSize: 40,
        fontWeight: 'bold'
    },
    title2: {
        fontSize: 28,
        fontWeight: 'bold'
    },
    r1: {
        height: '15%',
        backgroundColor: '#fae820',
        paddingVertical: 30,
        paddingHorizontal: 50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    r2: {
        height: '35%',
        paddingHorizontal: 50,
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    r3: {      
        marginBottom: 30,
        alignItems: 'center'
    },
    terms: {
        marginTop: 10,
        fontFamily: 'Candara',
        fontSize: 18
    },
    dollar: {
        fontFamily: 'Comic Sans MS',
        fontSize: 30,
    },
    modal: {
        backgroundColor: '#ABEBC6',
        padding: 20,
    },
})