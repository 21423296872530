import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import server from '../config/server';
import useAuth from '../hooks/useAuth';

export default function Credit() {

    const { user } = useAuth();
    const domain = server['domain'];

    return (
        <View style={styles.container}>
            <View style={styles.r1}>
                <Text style={styles.title}>Credits</Text>
            </View>
            <View style={styles.r2}>
                <Image style={styles.usa} source={{uri: domain + '/images/common/usa.jpg'}} />
                <Text style={styles.usdollar}>US Dollar</Text>
            </View>
            <View style={styles.r3}>
                <Text style={styles.balance}>${user.credit}</Text>
                <Text style={styles.usd}>USD</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: '#D1F2EB',
        borderRadius: 8,
    },
    r2: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 15
    },
    r3: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    r4: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    },
    r5: {
        flex: 1,
    },
    r6: {
        padding: 10,
        borderWidth: 2,
        borderColor: '#00BFFF',
        borderStyle: 'dashed'
    },
    m1: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    m2: {
        margin: 20,
        backgroundColor: '#D6EAF8',
        borderRadius: 10,
        padding: 30,
        alignItems: 'flex-start',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    subtitle: {
        fontFamily: 'Calibri',
        fontSize: 15,
    },
    button: {
        width: 28,
        height: 28,
        borderRadius: 14,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        alignSelf: 'flex-end',
    },
    buttonClose: {
        backgroundColor: "lightblue",
    },
    x: {
        color: 'white',
        textAlign: "center",
        fontSize: 16
    },
    usa: {
        width: 40,
        height: 25
    },
    usdollar: {
        marginLeft: 10
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    balance: {
        color: 'black',
        fontSize: 50,
        marginRight: 20
    },
    rule: {
        fontSize: 18,
        fontFamily: 'Candara',
    },
    message: {
        fontSize: 16,
        fontFamily: 'Candara'
    },
    usd: {
        fontSize: 18,
        position: 'relative',
        top: 30
    }
})