import React, { useState, useEffect } from 'react';
import { View, Text, Button, FlatList, Pressable, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Popup from './Popup';
import Invoice from './Invoice';
import api from '../api/client';

export default function MemberOrder({ personID }) {
    const [data, setData] = useState([]);
    const navigation = useNavigation();
    
    const [orderID, setOrderID] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Order/M/' + personID);
        setData(data);
    }

    const handlePay = (id, amount) => {
        navigation.navigate("PayPal", { id: id, amount: amount });
    }

    const handleCancel = async (id) => {
        await api.put('/Order/Delete/' + id);
        fetchData();
    }

    const viewInvoice = (id) => {
        setOrderID(id);
        setShowPopup(true);
    }

    const renderHeader = () => (
        <View style={[styles.row, {backgroundColor: '#D6EAF8'}]}>
            <View style={styles.v1}>
                <Text style={styles.text}>Date</Text>
            </View>
            <View style={styles.v2}>
                <Text style={styles.text}>Package Type</Text>
            </View> 
            <View style={styles.v3}>
                <Text style={styles.text}>Amount</Text>
            </View>
            <View style={styles.v4}>
                <Text style={styles.text}>Payment<br />Method</Text>
            </View>
            <View style={styles.v5}>
                <Text style={styles.text}>Paypal Reference ID</Text>
            </View>
            <View style={styles.v6}>
                <Text style={styles.text}></Text>
            </View>
            <View style={styles.v7}>
                <Text style={styles.text}></Text>
            </View>
        </View>
    );

    const renderItem = ({item}) => {
        const { id, membership, amount, paymentMethod, paypalID, paidDate } = item;
        let paid = paidDate.substring(0, 10);
        if (paid.startsWith('0001-')) paid = null;

        return (
        <View style={styles.row}>
            <View style={styles.v1}>
                {paid && <Text>{paidDate.substring(0, 10)}</Text>}
            </View>
            <View style={styles.v2}>
                <Text>{membership}</Text>
            </View>  
            <View style={styles.v3}>
                <Text>${amount}</Text>
            </View>
            <View style={styles.v4}>
                <Text>{paymentMethod}</Text>
            </View>
            <View style={styles.v5}>
                <Text>{paypalID}</Text>
            </View>
            <View style={styles.v6}>
                {!paid &&
                <View style={{flexDirection: 'row', paddingHorizontal: 5}}>
                    <View style={{flex: 1}}>
                        <Button title="Pay" color="#4CBB17" onPress={() => handlePay(id, amount)} />
                    </View>
                    <View style={{flex: 1, alignItems: 'center', justifyContent:'center'}}>
                        <Pressable onPress={() => handleCancel(id)}>
                            <Text style={{color: 'dodgerblue', fontSize: 16}}>cancel</Text>
                        </Pressable>
                    </View>
                </View>}
            </View>
            <View style={styles.v7}>
                {paid && <Pressable onPress={() => viewInvoice(id, 'M')}>
                    <Text style={{color: 'dodgerblue', fontSize: 16}}>receipt</Text>
                </Pressable>}
            </View>
        </View>
        );
    }

    return (
        <View style={styles.container}>
            {data.length > 0 && renderHeader()}
            <FlatList
                data={data}
                numColumns={1}
                keyExtractor={item => item.id}
                renderItem={renderItem}
                style={styles.list}
            ></FlatList>
            <Popup show={showPopup} onClose={() => setShowPopup(false)}>
                <Invoice orderID={orderID} type={'M'} />
            </Popup>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '95%'
    },
    row: {
        width: '100%',
        height: 50,
        backgroundColor: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: 'lightgray',
    },
    v1: {
        width:'10%',
        alignItems: 'flex-start',
        paddingLeft: 20
    },
    v2: {
        width:'20%',
    },
    v3: {
        width:'10%',
    },
    v4: {
        width:'10%',
    },
    v5: {
        width:'20%',
    },
    v6: {
        width:'20%',
        paddingRight: 20
    },
    v7: {
        width:'10%',
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 16
    },
})