import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import useAuth from '../hooks/useAuth';

const TopTabs = ({ navigation }) => {
  const { user } = useAuth();
  return (
    <>
        {user?
            <View style={styles.row}>
                <Pressable style={[styles.button, {flex: 1, backgroundColor: '#82E0AA', marginLeft: 0}]} onPress={() => navigation.navigate("Account")}>
                    <Text style={styles.text}>Account</Text>
                </Pressable>
                {/* <Pressable style={[styles.button, {flex: 2, backgroundColor: '#A020F0', flexDirection: 'row'}]} onPress={() => navigation.navigate("Cart")}>
                    <Text style={[styles.text, {color: 'white', marginRight: 10}]}>Shopping Cart</Text>
                    <Entypo name='shopping-bag' color='white' size={22} />
                </Pressable> */}
                <Pressable style={[styles.button, {flex: 1, backgroundColor: '#EAF2F8', marginRight: 0}]} onPress={() => navigation.navigate("Logout")}>
                    <Text style={[styles.text]}>Logout</Text>
                </Pressable>
            </View>
            :
            <View style={styles.row}> 
                <Pressable style={[styles.button, {flex: 1, backgroundColor: '#82E0AA', marginLeft: 0}]} onPress={() => navigation.navigate("Login")}>
                    <Text style={styles.text}>Login</Text>
                </Pressable>
                <Pressable style={[styles.button, {flex: 1, backgroundColor: '#00BFFF', marginRight: 0}]} onPress={() => navigation.navigate("Register")}>
                    <Text style={styles.text}>Register</Text>
                </Pressable>
            </View>}
      </>
  );
};

export default TopTabs;

const styles = StyleSheet.create({
    row: {
      flex: 1,
      flexDirection: 'row'
    },
    button: {
      alignItems: 'center',
      justifyContent: 'center', 
      margin: 5,
      paddingVertical: 6
    },
    text: {
      fontFamily: 'Comic Sans MS',
      fontSize: 18,
      color: 'black',
    }
});