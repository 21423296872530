import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import TopicChapter from '../components/TopicChapter';

const Stack = createNativeStackNavigator();

const TopicNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="TopicChapter" component={ TopicChapter } options={{ title: '返回' }} />
            {/* <Stack.Screen name="TopicText" component={ TopicText } options={{ headerShown: false }} />
            <Stack.Screen name="TopicSent" component={ TopicSent } options={{ headerShown: false }} /> */}
        </Stack.Navigator>
    );
}

export default TopicNavigator;