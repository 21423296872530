import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import PaymentPlan from '../components/PaymentPlan';
import Deduction from '../components/Deduction';
// import PaymentOption from '../components/PaymentOption';
// import Alipay from '../components/Alipay';
import PayPal from '../components/PayPal';
import Receipt from '../components/Receipt';

const Stack = createNativeStackNavigator();

const PlanNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>
            <Stack.Screen name="PaymentPlan" component={PaymentPlan} options={{ headerShown: false }} />
            {!user && <Stack.Screen name="Login3" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            <Stack.Screen name="Deduction" component={Deduction} options={{ headerShown: false }} />
            {/* <Stack.Screen name="PaymentOption" component={PaymentOption} options={{ headerShown: false }} /> */}
            <Stack.Screen name="PayPal" component={PayPal} options={{ headerShown: false }} />
            {/* <Stack.Screen name="Alipay" component={Alipay} options={{ headerShown: false }} /> */}
            <Stack.Screen name="Receipt" component={Receipt} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default PlanNavigator;