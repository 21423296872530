import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Countdown from './Countdown';

export default function PlanPrice({ price, priceNow, days, deadline }) {

  //  console.log('deadline: ', deadline);

    const show = new Date(deadline) > new Date();

    const onSale = priceNow < price;
    const raw = priceNow / days;
    const pricePerDay = (Math.round(raw * 100) / 100).toFixed(2);

    return (
        <View style={styles.container}>
            <Text style={[styles.price, {textDecoration: onSale? 'line-through':'none'}]}>${price}</Text>
            <View style={{minHeight: 60}}>
                {onSale && <Text style={styles.priceNow}>${priceNow}</Text>}
            </View>
            <View style={{flexDirection: 'row', marginTop: 10, justifyContent: 'center'}}>
                <Text style={styles.pricePerDay}>$ {pricePerDay}</Text>
                <Text style={{fontSize: 20, marginLeft: 5}}>per day</Text>
            </View>
            {/* {onSale && show && <Countdown deadline={deadline} />} */}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        minHeight: '40%',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 10,
        paddingVertical: 10
    },
    price: {
        fontFamily: 'Arial',
        fontSize: 50,
        color: 'white',
    },
    priceNow: {
        fontFamily: 'Arial',
        fontSize: 50,
        color: '#FF004C',
    },
    pricePerDay: {
        fontFamily: 'Arial',
        fontSize: 30,
    },
})