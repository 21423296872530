import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Price from './Price';

export default function ProductInfo({ brand, name, option, price }) {
    return (
        <View style={styles.container}>
            <View style={{flex: 1}}>
                <Text style={styles.brand}>{brand.toUpperCase()}</Text>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.option}>{option}</Text>
            </View>
            <View style={{flex: 1, justifyContent:'flex-end'}}>
                <Price price={price} size={18} />
            </View>
        </View>  
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 15
    },
    brand: {
        fontFamily: 'Candara',
        fontSize: 16
    },
    name: {
        fontFamily: 'Candara',
        fontSize: 20
    },
    option: {
        fontFamily: 'Calibri',
        fontSize: 18
    }
})