import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import server from '../config/server';

export default function Coupon({ user }) {
    const domain = server['domain'];
   
    if (user == null || user.coupon == null)
    return (
        <View style={{alignItems: 'center'}}>
            <Image style={styles.social} source={{uri: domain + '/images/common/social-media.png'}} />
        </View> 
    )

    const { coupon, memberToDate } = user;
    const expired = Date.parse(memberToDate) < new Date();
    
    return (
        <View style={styles.c1}>
            <View style={styles.c2}>
                <Text style={styles.code}>{coupon}</Text>
                <Text style={styles.off}>20 USD OFF</Text>
                <Text style={styles.expire}>Expires on: {memberToDate.substring(0,10)}</Text>
            </View>
            {expired && <Image style={styles.expired} source={{uri: domain + '/images/common/expired.png'}} />}
        </View>  
    );
}

const styles = StyleSheet.create({
    c1: {
        width: '70%',
        height: 'auto',
        backgroundColor: 'white',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'dashed 10px #00aef0',
        padding: 10
    },
    c2: {
        width: '100%',
        backgroundColor: '#00aef0',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
    },
    code: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 70,
        color: 'white'
    },
    off: {
        fontFamily: 'Candara',
        fontSize: 30,
        color: 'white',
    },
    expire: {
        marginTop: 10,
        fontFamily: 'Comic Sans MS',
        fontSize: 26,
        color: '#FE9600'
    },
    expired: {
        position: 'absolute',
        top: 0,
        right: -120,
        width: 268,
        height: 106,
        transform: 'rotate(30deg)'
    },
    social: {
        width: 200,
        height: 200
    },
})