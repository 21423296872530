import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import HSKMenu from '../components/HSKMenu';
import HSKLessons from '../components/HSKLessons';
import LessonTabs from './LessonTabs';
import PlanNavigator from './PlanNavigator';

const Stack = createNativeStackNavigator();

const HSKNavigator = () => {
    const { user } = useAuth(); // ?
    return (
        <Stack.Navigator>
            <Stack.Screen name="HSK" component={ HSKMenu } options={{ headerShown: false }} />
            <Stack.Screen name="HSKLessons" component={ HSKLessons } options={{ title: '返回 HSK 菜单' }} />
            {!user && <Stack.Screen name="LoginHSK" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            <Stack.Screen name="PlanHSK" component={ PlanNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="LessonTabs" component={ LessonTabs } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default HSKNavigator;