import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import PlanNavigator from './PlanNavigator';
import CultureMenu from '../components/CultureMenu';
import Culture from '../components/Culture';
import CultureText from '../components/CultureText';

const Stack = createNativeStackNavigator();

const CultureNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>
            <Stack.Screen name="CultureMenu" component={ CultureMenu } options={{ title: '返回' }} />
            {!user && <Stack.Screen name="Login6" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            <Stack.Screen name="Plan6" component={ PlanNavigator } options={{ headerShown: false }} />
            <Stack.Screen name="Culture" component={ Culture } options={{ headerShown: false }} />
            <Stack.Screen name="CultureText" component={ CultureText } options={{ headerShown: false }} />
        </Stack.Navigator>
    );
}

export default CultureNavigator;