import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useAuth from '../hooks/useAuth';

import Login from '../components/Login';
import Affiliate from '../components/Affiliate';
import PlanNavigator from './PlanNavigator';

const Stack = createNativeStackNavigator();

const AffiliateNavigator = () => {
    const { user } = useAuth();
    return (
        <Stack.Navigator>      
            <Stack.Screen name="Affiliate" component={Affiliate} options={{ headerShown: false }} />
            {!user && <Stack.Screen name="Login1" options={{ headerShown: false }}>
                {data => <Login {...data} />}
            </Stack.Screen>}
            {user && !user.isMember && <Stack.Screen name="Plan3" component={PlanNavigator} options={{ headerShown: false }} />}
        </Stack.Navigator>
    );
}

export default AffiliateNavigator;