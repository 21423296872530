import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from 'react-native';
import Logo from './Logo';
import api from '../api/client';

export default function Invoice({ orderID, type }) {
    const [order, setOrder] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Order/Invoice/' + orderID + '/' + type);
       // console.log(JSON.stringify(data));
        setOrder(data);
    }

    return (
        order &&
        <View style={styles.container}>
            <Logo color='black' />
            <Text style={styles.title}>Receipt</Text>
            <View style={styles.row}>
                <View style={styles.box}>
                    <Text>Order No.</Text>
                </View>
                <View style={[styles.box, {borderLeftWidth: 0}]}>
                    <Text>{order.orderID}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.box}>
                    {order.address == undefined? <Text>Description</Text>:<Text>Shipping Address</Text>}
                </View>
                <View style={[styles.box, {borderLeftWidth: 0}]}>
                    {order.address == undefined? <Text>{order.description}</Text>:<Text>{order.address}</Text>}
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.box}>
                    <Text>Account</Text>
                </View>
                <View style={[styles.box, {borderLeftWidth: 0}]}>
                    <Text>{order.email}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.box}>
                    <Text>Payment Method</Text>
                </View>
                <View style={[styles.box, {borderLeftWidth: 0}]}>
                    <Text>{order.paymentMethod}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.box}>
                    <Text>Amount</Text>
                </View>
                <View style={[styles.box, {borderLeftWidth: 0}]}>
                    <Text>${order.amount} USD</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={[styles.box, {borderBottomWidth: 1}]}>
                    <Text>Paid Time</Text>
                </View>
                <View style={[styles.box, {borderLeftWidth: 0, borderBottomWidth: 1}]}>
                    <Text>{order.paidDate}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        zIndex: 10,
    },
    title: {
        fontFamily: 'Candara', 
        fontSize: 28,
        marginVertical: 20
    },
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    box: {
        width: '50%',
        padding: 15,
        justifyContent: 'center',
        borderColor: 'lightgray',
        borderWidth: 1,
        borderBottomWidth: 0
    },
    text: {
        fontSize: 20,
    },
    orderID: {
        fontFamily: 'Candara',
        fontSize: 25,
    }
})