import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, Pressable, StyleSheet } from 'react-native';

import api from '../api/client';
import { Audio } from 'expo-av';

export default function ProverbList({ pageID, pageSize, onSelect }) {

    const [cID, setCID] = useState(0);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const { data } = await api.get('/Advanced/Proverb/' + pageID + '/' + pageSize);
        setData(data);

        setCID(data[0].cid);
        onSelect(data[0].cid);
    }

    const playSound = async () => {
        // const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/proverb/' + cID + '.m4a'});
        // await sound.playAsync();
    }

    useEffect(() => {
        fetchData();
    }, [pageID]);

    const handleSelect = (cid) => {
        setCID(cid);
        onSelect(cid);
        playSound();
    }

    return (
        <ScrollView>
            <View style={styles.list}>
                {data.map(item =>
                    <View key={item.cid}>
                        <Pressable onPress={() => handleSelect(item.cid)}>
                            <View style={[styles.card, item.cid == cID? { backgroundColor:'pink'}:{backgroundColor:'#dcf3ff'}]}>
                                <Text style={styles.chinese}>{item.chinese}</Text>
                            </View>
                        </Pressable>
                    </View>
            )}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
    },
    left: {
        width: '70vw'
    },
    right: {
        width: '16vw'
    },
    pages: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 50
    },
    card: {
        backgroundColor: '#dcf3ff',
        padding: 20,
        margin: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    list: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
    chinese: {
        fontFamily: 'STZhongsong',
        fontSize: 40,
        letterSpacing: 10,
    },
    english: {
        fontFamily: 'Calibri',
        fontSize: 22
    },
})