import React from 'react';
import { Text, StyleSheet } from 'react-native';

export default function MemberDate({ date }) {

    const diff = new Date(date) - new Date();
    const days = Math.ceil(diff / (1000 * 3600 * 24));
    const dateColor = days < 10? {color: '#FF004C'} : {color: 'black'};

    return (
        <Text style={[styles.date, dateColor]}>{ date.substring(0,10) }</Text>
    );
}

const styles = StyleSheet.create({ 
    date: {
        fontFamily: 'Comic Sans MS',
        fontSize: 18,
        marginHorizontal: 20,
    },
})