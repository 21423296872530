import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import server from '../config/server';

export default function Receipt({ route }) {
    const { date } = route.params;
    const domain = server["domain"];

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Image style={styles.image} source={{uri: domain + '/images/common/receipt.jpg'}} />
            </View>
            <View style={styles.right}>
                <Text style={styles.title}>Thank you for your order.</Text>
                <Text style={styles.text}>Your membership updated to {date}</Text>
                <Text style={styles.text}>Please log out and log in again to update membership data.</Text>
                {/* <Text style={styles.orderID}>Order ID:  {orderID}</Text> */}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: '50%',
        justifyContent: 'center',
    },
    image: {
        width: 396,
        height: 414,
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 40,
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 30,
        marginTop: 40
    },
    orderID: {
        fontFamily: 'Candara',
        fontSize: 25,
    }
})