import React, { useState, useEffect } from 'react';
import { View, Image, Text, TextInput, Dimensions, StyleSheet } from 'react-native';
import { Form, FormField, SubmitButton, Message, ErrorMessage, FormBox } from './form';

import * as Yup from 'yup';
import api from '../api/client';
import server from '../config/server';
import useAuth from '../hooks/useAuth';

const schema = Yup.object().shape({
    name: Yup.string().required().min(2).max(60).label("Your Name"),
    email: Yup.string().required().email().label("Your Email"),
    message: Yup.string().required().min(20).max(1000).label("Message")
})

export default function EmailForm() {
    const domain = server['domain'];
    const { user } = useAuth();

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const [width, setWidht] = useState(900);
    const [height, setHeight] = useState(460);

    const initialValues = {
        name: user? user.firstName + ' ' + user.lastName : '',
        email: user? user.email : '',
        message: ''
    }

    useEffect(() => {
        calculateSize();
    }, []);

    const calculateSize = () => {
        let w = Math.round(Dimensions.get('window').width * 0.5);  //0.85 * 0.7 ~= 0.6
        let h = Math.round((w / width) * height);
        setWidht(w);
        setHeight(h);
    }

    const sendMail = async (name, email, message) => {
        setError('');
        setSuccess(false);

        if (message.includes("script"))
            return setError('Malicious text detected.');

        let v = new FormData();
        v.append("PreID", 0);
        v.append("SenderID", user? user.personID : 0);
        v.append("Name", name.trim());
        v.append("SenderEmail", email);
        v.append("Text", message);

        const { data } = await api.post("/Chat/", v);

        if (data == 1) {
            setSuccess(true);
            setError('');
        } else if (data == -1) {
            setSuccess(false);
            setError('There occurred a problem. Please try again later.');
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Text style={styles.title}>CONTACT US</Text>
                <Form
                    initialValues={initialValues}
                    onSubmit={({name, email, message}) => sendMail(name, email, message) }
                    validationSchema={schema}
                >
                    <View style={{marginBottom: 10}}>
                    {error.length > 0 && <ErrorMessage text={error} />}
                    {success && <Message text="Thank you for contacting us. We will get back to you within 2 business days." />}
                    </View>
                    <FormField 
                        name="name"
                        icon="panda"
                        placeholder={user? user.firstName + ' ' + user.lastName : 'Your Name'}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                    />
                    <FormField 
                        name="email"
                        icon="email"
                        placeholder={user? user.email : 'Your Email'}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                    />
                    <FormBox
                        name="message"
                        placeholder="Message"
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                    />
                    <View style={{width: '100%', marginTop: 10, marginVerBottom: 20 }}>
                        <SubmitButton text="Send" />
                    </View>
                </Form>
            </View>
            <View style={styles.right}>
                <Image source={{uri: domain + '/images/common/pencil.jpg'}}
                       style={[styles.image, {width: width, height: height}]} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 40,
        zIndex: 10,
        position: 'relative',
        left: 50
    },
    right: {
        width: '50%',
        zIndex: 0,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    image: {
        resizeMode: 'contain',
        right: 20,
        zIndex: -1
    },
    title: {
        fontFamily: 'Arial',
        fontSize: 40,
        color: '#FE9600',
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    subTitle: {
        fontFamily: 'Candara',
        fontSize: 20,
        marginVertical: 10,
        alignSelf: 'center'
    },
})