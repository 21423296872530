import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native';
import { Audio } from 'expo-av';
import color from '../config/color';
import server from '../config/server';

export default function VocabularyCard({ data }) {
    const { hsk, textID, cixing, pinyin, chinese, english, audioID } = data;
    const [characters, setCharacters] = useState([]);
    const c = color['' + cixing];
    const domain = server['domain'];

    useEffect(() => {
        splitWords(pinyin, chinese);
    }, []);

    const splitWords = (pinyin, chinese) => {
        let words = [];
        let pins = [];

        if (chinese.length == 1) {
            pins = [pinyin];
            words = [chinese];
        } else {
            pins = pinyin.split(/\s+/);
            words = chinese.split(/\s+/);

            if (pins.length != words.length)
                words = [...chinese];
        }
 
        let combine = words.map((e, i) => [e, pins[i]]);
        setCharacters(combine);
    }

    const renderItem = ({item}) => {
        return (
            <View style={styles.word}>
                <Text style={styles.pinyin}>{item[1]}</Text>
                <Text style={styles.chinese}>{item[0]}</Text>
            </View>
        );
    }

    const playSound = async () => {
        const { sound } = await Audio.Sound.createAsync({uri: domain + '/sound/text/HSK' + hsk + '/' + textID + '/v/' + audioID + '.mp3'});
        await sound.playAsync();
    }

    return (
        <Pressable onPress={() => playSound()}>
            <View style={[styles.container, {backgroundColor: c}]}>
                <FlatList
                    data={characters}
                    keyExtractor={(item, index) => index}
                    renderItem={renderItem}
                    horizontal={true}
                ></FlatList>
                <Text style={styles.english}>{english}</Text>
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        margin: 20,
        padding: 20,
        borderRadius: 10,
        minWidth: 200,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    word: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    pinyin: {
        fontFamily: 'calibri',
        fontSize: 25,
    },
    chinese: {
        fontFamily: 'MicrosoftBlack',
        fontSize: 50,
        letterSpacing: 10,
    },
    english: {
        fontFamily: 'Comic Sans MS',
        fontSize: 22
    },
})