import React, { useState, useEffect } from 'react';
import { View, Image, Text, Pressable, StyleSheet } from 'react-native';
import CouponForm from './CouponForm';
import CreditOption from './CreditOption';
import DeductionTable from './DeductionTable';

import useAuth from '../hooks/useAuth';
import server from '../config/server';

export default function Deduction({ navigation, route }) {

    const { user } = useAuth();
    const { amount } = route.params;
    const domain = server['domain'];

    const [coupon, setCoupon] = useState(null);
    const [creditUsed, setCreditUsed] = useState(0);
    const [total, setTotal] = useState(amount);

    const gotoPay = () => {
        navigation.navigate("PayPal", { amount: total, type: 3, coupon: coupon, credit: creditUsed });
    }

    useEffect(() => {
        let discount = coupon? 20:0;
        setTotal(amount - discount - creditUsed);
    }, [creditUsed, coupon]);

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Text style={styles.title}>Coupon and Credit</Text>
                <View style={styles.box1}>
                    <Image style={styles.image} source={{uri: domain + '/images/common/tag2.jpg'}} />
                </View>
            </View>
            <View style={styles.right}>
                <View style={styles.r1}>
                    <CouponForm onCheck={c => setCoupon(c)} />
                </View>
                
                {user.credit > 0 && <View style={styles.r2}>
                    <CreditOption sum={amount} credit={user.credit} onSelect={c => setCreditUsed(c)} />
                </View>}

                <View style={styles.r3}>
                    <DeductionTable original={amount} coupon={coupon} credit={creditUsed} /> 
                </View>

                <Pressable style={styles.button} onPress={() => gotoPay()}>
                    <Text style={styles.payText}>{"Pay    $"} {total}</Text>
                </Pressable>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    left: {
        width: '35%',
        padding: 30,
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightColor: 'black',
        borderRightWidth: 3,
        borderStyle: 'dotted'
    },
    right: {
        backgroundColor: '#EAF2F8',
        flex: 1,
        width: '65%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    box1: {
        height: '80%',
        alignItems: 'flex-end',
        justifyContent: 'center',  
    },
    image: {
        resizeMode: 'contain',
        width: 450,
        height: 378
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 35,
        fontWeight: 'bold'
    },
    r1: {
        width: '90%',
        height: 'auto',
    },
    r2: {
        width: '90%',
        marginTop: 20,
    },
    r3: {
        width: '90%',
        marginTop: 20,
        alignItems: 'center',
    },
    button: {
        width: '90%',
        height: 50,
        marginTop: 20,
        backgroundColor: '#4CBB17',
        flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        borderRadius: 5,
    },
    payText: {
        fontFamily: 'Calibri',
        color: 'white',
        fontSize: 25,
    }
})